const now = new Date()

export default {
  general: {
    add: 'Adicionar',
    apple_store: 'Apple store',
    available_on: 'Disponível no',
    back: 'Voltar',
    back_to_start: 'Voltar ao início',
    banner_24_hour: '24 horas',
    banner_online_attendance: 'Atendimento ',
    banner_per_day: 'por dia.',
    banner_feedback: 'Avaliação',
    banner_my_attendances: 'Minhas consultas',
    banner_cancel_attendance: 'Cancelar consulta',
    banner_faq: 'Dúvidas frequentes',
    benefits: 'Benefícios',
    cancel: 'Cancelar',
    close: 'Fechar',
    confirm: 'Confirmar',
    confirmation: 'Confirmação',
    consent_term: 'Termo de Consentimento',
    crm: 'crm',
    continue: 'Continuar',
    delete: 'Excluir',
    dependents: 'Dependentes',
    dr_a: 'Dr.(a)',
    download_at: 'Baixe na',
    error: 'Erro',
    exit: 'Sair',
    email_or_phone_unverified_description: 'É importante confirmar seu endereço de email e celular para que você receba notificações e informações sobre o seu atendimento.',
    confirm_now: 'Deseja confirmar agora?',
    faq: 'FAQ',
    female: 'Feminino',
    frequently_asked_questions: 'Dúvidas frequentes',
    footer: 'Copyright ® ' + now.getFullYear() + ' - Teladoc Health - Todos os direitos reservados. Qualquer cópia ou reprodução de textos, imagens ou conteúdo, sem autorização prévia, poderá sofrer processo judicial.',
    general_error: 'Tivemos um problema técnico inesperado. Tente novamente mais tarde ou entre em contato em um de nossos canais de atendimento:',
    google_play: 'Google play',
    holder: 'Títular',
    home: 'Home',
    later: 'Depois',
    data_changed_successfully:'As alterações foram salvas com sucesso!',
    male: 'Masculino',
    my_attendances: 'Minhas Consultas',
    my_profile: 'Meu perfil',
    next: 'Próximo',
    next_attendances: 'Proximas consultas',
    next_attendance: 'Proxima consulta',
    not_informed: 'Não informado',
    no: 'Não',
    ok: 'OK',
    ok_got_it: 'Ok, entendi',
    patient: 'Paciente',
    patient_selection_title: 'Para quem é a consulta?',
    privacy_policy: 'Política de Privacidade',
    psychology: 'Psicologia',
    required: 'Campo de preenchimento obrigatório.',
    send: 'Enviar',
    select: 'Selecionar',
    select_options_below_benefits: 'Selecione uma das opções abaixo para alterar o seu benefício:',
    select_options_below_patients: 'Selecione para qual paciente será a consulta:',
    start: 'Iniciar',
    time: 'Horário',
    title_select_benefits: 'Selecione o seu benefício',
    unavailable: 'Indisponível',
    unavailable_patient_text: 'Este paciente possui um cadastro em nosso sistema e está indisponível para consultas como dependente. Por gentileza, efetue o login correspondente para continuar com a sua solicitação.',
    years: 'anos',
    yes: 'Sim',
    warning: 'Aviso',
    warning_patient_selection_1: 'Caso algum dependente legal não esteja aparecendo na lista de seleção de pacientes,',
    warning_patient_selection_2: 'entre em contato com o RH de sua empresa.',
    banner_need_attendance: 'Preciso de um atendimento',
    programs_speciality: 'Especialidades médicas',
    programs_health_care: 'Bem-estar',
    programs_title_text: 'Selecione a especialidade:',
    programs_title_text_2: 'Especialidades Médicas',
    programs_subtitle_text: 'Utilize o botão abaixo para alternar entre especialidades médicas e de bem-estar.',
    programs_subtitle_text_2: 'Selecione uma especialidade abaixo para continuar.',
    restricted_medications: 'Medicamentos restritos como anfetaminas e benzodiazepínicos não são prescritos eletronicamente.',
    reschedule: 'Reagendar',
    warning_logout: 'Deseja sair da conta logada e retornar a tela inicial?',
    schedule_confirm: 'Confirmar consulta',
    attention: 'Atenção',
    attention_text: 'Ao confirmar a consulta você estará de acordo com o ',
    attention_terms: 'termo de consentimento.',
    file_exclude: 'Tem certeza que deseja excluir este arquivo?',
    invalid_file_type: 'Tipo de arquivo não permitido!',
    invalid_file_size: 'Arquivo não pode ser maior do que 15MB!',
    invalid_file_count: 'Não é possível anexar mais do 5 arquivos!',
    schedule_cancel: 'Sua consulta será perdida e será necessário realizar um novo atendimento. Deseja continuar?',
    success: 'Sucesso',
    schedule_attendance_success: 'Sua consulta com um de nossos profissionais foi agendada com sucesso!',
    schedule_attendance_success_alert_medical: 'Fique atento às suas notificações. Você receberá um aviso quando o médico entrar na sala de vídeo.',
    schedule_attendance_success_alert_signal: 'Antes da consulta, esteja em um local privativo, bem iluminado e verifique sua conexão com a internet.',
    OPENED: 'Agendado',
    ATTENDANT_CANCELED: 'Cancelado pelo atendente',
    PATIENT_CANCELED: 'Cancelado pelo paciente',
    CLEARED: 'Finalizado',
    ATTENDED: 'Atendido',
    DOCTOR_PENDING: 'Pendente',
    SCHEDULED_PENDING: 'Agendado',
    ATTENDING: 'Atendendo',
    ENDED: 'Finalizado',
    resume_medical: 'Resumo da consulta',
    case_number: 'Número do caso',
    prescription: 'prescrição',
    attest: 'atestado médico',
    report_medical: 'relatório médico',
    exams: 'solicitação de exames',
    history_patient: 'Paciente:',
    history_date: 'Data:',
    history_hour: 'Horário:',
    history_status: 'Status:',
    history_prescription: 'Use a opção abaixo para ver sua prescrição médica.',
    history_attest: 'Use a opção abaixo para ver sua declaração médica.',
    history_details: 'Detalhes de consulta',
    history_doubt: 'Aconteceu algum problema durante sua consulta ou possui alguma dúvida?',
    history_contact: 'Entre em contato com a Teladoc e informe o número do caso para dar continuidade ao seu atendimento.',
    history_support: 'suporte teladoc',
    history_sac: '0800 835 2362',
    history_sac_call: 'Ligue gratuitamente e tire suas dúvidas',
    history_sac_week: 'Segunda a Sexta - 09h:00 as 17h:00',
    home_sac: '0800 835 2362',
    open_pdf: 'Abrir PDF',
    number_attendance_center: '0800 835 2362',
    psychology_under_age: 'O atendimento de psicologia não está disponível para pacientes menores de 14 anos',
    psychology_schedule_full: 'O agendamento de psicologia não está disponível pois já existe uma consulta agendada para este paciente.',
    reschedule_medical: 'Reagendar consulta',
    when_you_need_it_most: 'quando você mais precisa',
    online: 'online',
    talk_with_us_description: "Possui alguma dúvida ou sugestão? \n Entre em contato por um dos canais abaixo:",
    understood: 'Ok, entendi.',
    warning_inelegible_patient: {
      attention: "Atenção:",
      text: "Você não está mais elegível para atendimento. Por gentileza, entre em contato com a pessoa responsável pela sua conta."
    },
    email: 'E-mail',
    email_relationship: 'relacionamento@teladochealth.com'
  }
};