import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * POST
  * @param company_id: int
  * @param group_id: int
  */
  programGetDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PROGRAM_GET_DETAILS, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setProgram', null));

        if (response.data.data.program_info?.length) {
          response.data.data.program_info = [
            ...new Map(response.data.data.program_info.map(o => [o['program_id'], o])).values()
          ];
        }

        if (response.data.data.program_well_being?.length) {
          response.data.data.program_well_being = [
            ...new Map(response.data.data.program_well_being.map(o => [o['program_id'], o])).values()
          ];
        }

        resolve(commit('setPrograms', response.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
* POST
* @param case_id: int
* @param timestamp: int
* @param user_id: int
* @param patient_id: int
* @param program_id: int
* @param program_step: int
*/
  caseAttendanceAdd({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CASE_ATTENDANCE_ADD, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  scheduleSlots({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.SCHEDULE_SLOTS, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setScheduleSlots', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
 * POST
 * @param timestamp: int
 * @param program_id: int
 * @param user_schedule_id: int
 * @param member_id: int
 * @param user_id: int
 * @param company_id: int
 * @param bricks: str
 * @param double_booking: bool
 */
  programAttendanceAdd({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PROGRAM_ATTENDANCE_ADD, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param member_id: int
  * @param case_status: str
  * @param company_id: int
  * @param group_id: int
  * @param is_aso: bool
  * @param emergency_pass: bool
  */
  caseCreate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CASE_CREATE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param file: url
  */
  assetCreate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.ASSET_CREATE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  */
  checkDoubleBooking({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CHECK_DOUBLE_BOOKING, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setCheckDoubleBooking', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },


  checkPeriodSlot({ commit }, payload = null) {
    let restrictPayload = {
      user_schedule_id: payload.user_schedule_id,
      selected_date: payload.selected_date
    }
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CHECK_PERIOD_SLOT, restrictPayload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  verifySchedule({commit}, payload){
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.VERIFY_SCHEDULE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setVerifySchedule', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  }
};
