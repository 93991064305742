<template>
  <v-dialog
    v-model="modalDialog"
    :fullscreen="fullscreen"
    :hide-overlay="hideOverlay"
    :loading="loading"
    :max-width="maxWidth"
    :overlay-color="overlayColor"
    :overlay-opacity="overlayOpacity"
    :persistent="persistent"
    :scrollable="scrollable"
    :width="width"
    @click:outside="!persistent ? $emit('handleClose') : null"
    @keydown.esc="!persistent ? $emit('handleClose') : null"
    class="general-modal"
  >
    <v-card
      :color="backgroundColor"
      :class="{
        'left': left && !fullscreen,
        'right': right && !fullscreen,
        'bottom': bottom && !fullscreen,
        'top': top && !fullscreen
      }"
      class="overflow-hidden"
    >
      <!-- Header -->
      <v-card-title
        v-if="!hideHeader"
        class="header pa-0"
      >
        <v-toolbar
          :color="headerColor"
          class="d-flex justify-center overflow-hidden"
          dense
          extense
          flat
          floating
          height="90"
          width="100%"
        >
          <template slot="img">
            <v-container class="header-background pa-0" />
          </template>

          <template slot="content">
            <v-container class="pa-0" />
          </template>

          <!-- Image title -->
          <v-toolbar-title
            v-if="alert || error || success || logo || endAttendance || holdingPhone || approvedIcon"
            class="header-image d-flex"
          >
            <v-img
              v-if="alert"
              :src="require('@/assets/icons/alert.svg')"
              class="alert-header"
              contain
              width="96"
            />

            <v-img
                v-if="holdingPhone"
                :src="require('@/assets/icons/frame.svg')"
                class="phone-header"
                contain
                width="69"
            />

            <v-img
                v-if="approvedIcon"
                :src="require('@/assets/icons/approved-1516761.svg')"
                class="phone-header"
                contain
                width="65"
                heigh="64"
            />

            <v-img
              v-else-if="error"
              :src="require('@/assets/icons/error.svg')"
              class="error-header"
              contain
              width="80"
            />

            <v-img
              v-else-if="success"
              :src="require('@/assets/icons/success.svg')"
              class="success-header"
              contain
              width="70"
            />

            <v-img
              v-else-if="logo"
              :src="companyLogo || require('@/assets/logos/teladoc_health_modal.svg')"
              class="logo-header"
              contain
            />

            <v-img
              v-else-if="endAttendance"
              :src="require('@/assets/icons/end_attendance.svg')"
              class="end-attendance-header"
              contain
            />
          </v-toolbar-title>

          <!-- Text title -->
          <v-toolbar-title
            v-else
            class="d-flex"
          >
            <v-icon
              v-if="headerIcon"
              class="header-icon mr-4"
              color="secondary"
            >
              {{ headerIcon }}
            </v-icon>

            <h4
              v-if="headerTitle"
              class="header-title white--text"
            >
              {{ headerTitle }}
            </h4>

            <v-icon
              v-if="hasHeaderInfo"
              @click="$emit('handleHeaderInfo')"
              class="cy-modal-header-info-button header-info ml-4"
              color="secondary"
            >
              fa-circle-info
            </v-icon>
          </v-toolbar-title>
        </v-toolbar>

        <div class="close">
          <v-icon
            v-if="!hideHeader && hasClose"
            @click="$emit('handleClose')"
            class="cy-modal-header-close-button header-close mt-3 mr-3"
            color="white"
            size="25"
          >
            fa-xmark
          </v-icon>
        </div>
      </v-card-title>

      <!-- Content -->
      <v-card-text
        :class="[
          $device.mobile || generalError ? 'px-5' : 'px-10',
          $device.mobile && (!hasCancel && !hasConfirm) ? 'pb-7' : 'pb-9'
        ]"
      >
        <!-- Title -->
        <v-card-title
          v-if="contentIcon || contentTitle || (hideHeader && hasClose)"
          :class="[ hideHeader && $device.mobile ? 'pt-10 pb-0' : 'pt-6']"
          class="d-flex justify-space-between px-0 pb-0"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="contentIcon"
              class="content-icon mr-4"
              color="secondary"
            >
              {{ contentIcon }}
            </v-icon>

            <h3
              v-if="contentTitle"
              class="content-title primary--text"
            >
              {{ contentTitle }}
            </h3>
          </div>

          <v-icon
            v-if="hideHeader && hasClose"
            @click="$emit('handleClose')"
            class="content-close cy-modal-content-close-button ml-4"
            color="outerSpace"
            size="30"
          >
            fa-square-xmark
          </v-icon>
        </v-card-title>

        <!-- Content -->
        <div
          class="slot-container pt-6"
        >
          <slot />
        </div>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions
        v-if="hasCancel || hasConfirm"
        :class="[
          $device.mobile || generalError ? 'px-7 pb-7' : 'px-16 pb-9',
          invertActions ? 'flex-row-reverse' : ''
        ]"
        class="d-flex justify-center px-0"
      >
        <v-btn
          v-if="hasCancel"
          :class="{
            'mx-auto': !hasConfirm,
            'ml-0 mr-3': !invertActions && hasConfirm
          }"
          :max-width="$device.mobile ? 175 : 'auto'"
          :min-width="$device.mobile ? 135 : 195"
          @click="$emit('handleCancel')"
          class="cancel cy-modal-cancel-button outerSpace--text outline-border"
          depressed
          min-height="46"
          rounded
        >
          {{ cancelText || $t('general.cancel') }}
        </v-btn>

        <v-btn
          v-if="hasConfirm"
          :class="{
            'mx-auto': !hasCancel,
            'ml-0 mr-3': invertActions && hasCancel
          }"
          :max-width="$device.mobile ? 175 : 'auto'"
          :min-width="$device.mobile ? 135 : 195"
          @click="$emit('handleConfirm')"
          class="confirm cy-modal-confirm-button ma-0"
          color="outerSpace"
          dark
          depressed
          min-height="46"
          rounded
        >
          {{ confirmText || $t('general.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'GeneralModal',

    props: {
      // Controls if modal is active or not
      dialog: {
        type: Boolean,
        required: true,
        default: false
      },

      // Left position
      left: {
        type: Boolean,
        default: false
      },

      // Right position
      right: {
        type: Boolean,
        default: false
      },

      // Bottom position
      bottom: {
        type: Boolean,
        default: false
      },

      // Top position
      top: {
        type: Boolean,
        default: false
      },

      // Is in fullscreen?.
      fullscreen: {
        type: Boolean,
        default: false
      },

      // Can click outside?
      persistent: {
        type: Boolean,
        default: false
      },

      // Has scrollable content?
      scrollable: {
        type: Boolean,
        default: true
      },

      // Content is loading?
      loading: {
        type: Boolean,
        default: false
      },

      // Modal's max width
      maxWidth: {
        type: String,
        default: 'auto'
      },

      // Modal's width
      width: {
        type: String,
        default: 'auto'
      },

      // Sets the background color
      backgroundColor: {
        type: String,
        default: 'white'
      },

      // Alert type modal
      alert: {
        type: Boolean,
        default: false
      },

      holdingPhone: {
        type: Boolean,
        default: false
      },

      approvedIcon: {
        type: Boolean,
        default: false
      },

      // Error type modal
      error: {
        type: Boolean,
        default: false
      },

      // Success type modal
      success: {
        type: Boolean,
        default: false
      },

      // Logo type modal
      logo: {
        type: Boolean,
        default: false
      },

      // End Attendance type modal
      endAttendance: {
        type: Boolean,
        default: false
      },

      // Sets the header color
      headerColor: {
        type: String,
        default: 'primary'
      },

      // Sets the header icon
      headerIcon: {
        type: String,
        default: null
      },

      // Sets the header title
      headerTitle: {
        type: String,
        default: null
      },

      // Hides the header
      hideHeader: {
        type: Boolean,
        default: false
      },

      // Sets the content icon
      contentIcon: {
        type: String,
        default: null
      },

      // Sets the content title
      contentTitle: {
        type: String,
        default: null
      },

      // Hides the display of the overlay
      hideOverlay: {
        type: Boolean,
        default: false
      },

      // Sets the overlay color
      overlayColor: {
        type: String,
        default: '#00000088'
      },

      // Sets the overlay opacity
      overlayOpacity: {
        type: [Number, String],
        default: 1
      },

      // Shows the display of the cancel button
      hasCancel: {
        type: Boolean,
        default: false
      },

      // Shows the display of the confirm button
      hasConfirm: {
        type: Boolean,
        default: false
      },

      // Shows the display of the header/content close button
      hasClose: {
        type: Boolean,
        default: false
      },

      // Shows the display of the header info button
      hasHeaderInfo: {
        type: Boolean,
        default: false
      },

      // Sets the cancel button text
      cancelText: {
        type: String,
        default: ''
      },

      // Sets the confirm button text
      confirmText: {
        type: String,
        default: ''
      },

      // Invert actions buttons
      invertActions: {
        type: Boolean,
        default: false
      },
      generalError: {
        type: Boolean,
        default: false
      },

      // Function to handle cancel button and action
      handleCancel: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle close action
      handleClose: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle confirm button and action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle header info button and action
      handleHeaderInfo: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      modalDialog: false,
    }),

    beforeCreate() {
      // 
    },

    created() {
      // 
    },

    beforeMount() {
      // 
    },

    mounted() {
      // 
    },

    computed: {
      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo', // -> this.getCompanyLogo
      }),
    },

    watch: {
      dialog(newDialog) {
        this.modalDialog = newDialog;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .general-modal {
    min-height: 250px;
    min-width: 400px;
  }

  .left {
    left: 0;
    position: absolute;
    transform: translate(0, -50%);
    width: inherit;
  }

  .right {
    position: absolute;
    right: 0;
    transform: translate(0, -50%);
    width: inherit;
  }

  .bottom {
    bottom: 0;
    position: absolute;
    transform: none;
    width: inherit;
  }

  .top {
    position: absolute;
    top: 0;
    transform: none;
    width: inherit;
  }

  .header {
    padding: 0;
    width: 100%;
  }

  .header-background {
    background: url('@/assets/general/modal_header_background.svg');
    background-position: bottom;
    background-repeat: repeat;
    background-size: initial;
    height: calc(100% - 12px);
    left: 6px;
    max-width: calc(100% - 12px);
    opacity: 0.3;
    position: absolute;
    top: 6px;
    width: calc(100% - 12px);
  }

  .header-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .header-image {
    height: calc(100% - 12px);
  }

  .slot-container {
    height: calc(100% - 56px);
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    //
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 56px);
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 72px);
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 72px);
    }
  }
</style>
