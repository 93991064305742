<template>
  <GeneralModal
      :dialog="opened"
      :header-close="true"
      @handleClose="$emit('handleClose')"
      has-close
      header-title='Alterar e-mail'
      invert-actions
      width="550"
  >
    <v-form
        autocomplete="off"
    >
      <v-row
          class="ma-0"
      >

        <v-col
            class="px-0 pt-0"
            cols="12"
            md="12"
        >
          <v-text-field
              v-model.trim="email"
              backgroundColor="white"
              :error-messages="emailErrors"
              :label="$t('validate_phone_number.enter_new_email')"
              :placeholder="$t('validate_phone_number.enter_new_email')"
              @blur="$v.email.$touch()"
              @change="clearBackendError()"
              background-color="transparent"
              class="cy-mobile-number-valid-text mobile-number pa-0"
              persistent-placeholder
              height="50"
              outlined
          />
        </v-col>
      </v-row>

      <v-row
          class="ma-0 d-flex align-center justify-center"
      >
        <v-col
            class="px-0 pt-0"
            cols="6"
            md="6"
        >
          <v-btn
              :min-width="$device.mobile ? 165 : 175"
              @click="$emit('handleClose')"
              class="cy-cancel-button cancel text-capitalize outerSpace--text outline-border"
              depressed
              max-width="195"
              min-height="46"
              rounded
          >
            {{ $t('general.cancel') }}
          </v-btn>
        </v-col>

        <v-col
            class="px-0 pt-0"
            cols="6"
            md="6"
        >
          <v-btn
              :min-width="$device.mobile ? 165 : 175"
              :loading="loading"
              @click="validateEnterEmailCode"
              class="cy-send-button confirm text-capitalize ma-0"
              color="outerSpace"
              dark
              depressed
              max-width="195"
              height="46"
              rounded
          >
            {{ $t('general.confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </GeneralModal>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import {required, email} from 'vuelidate/lib/validators';
  import GeneralModal from "@/components/general/GeneralModal.vue";

  export default {
    name: 'ChangeEmailModal',
    components: {
      GeneralModal
    },
    props: {
      // Controls if modal is active or not
      dialog: {
        type: Boolean,
        required: true,
        default: false
      },

      // Left position
      left: {
        type: Boolean,
        default: false
      },

      // Right position
      right: {
        type: Boolean,
        default: false
      },

      // Bottom position
      bottom: {
        type: Boolean,
        default: false
      },

      // Top position
      top: {
        type: Boolean,
        default: false
      },

      // Is in fullscreen?.
      fullscreen: {
        type: Boolean,
        default: false
      },

      // Can click outside?
      persistent: {
        type: Boolean,
        default: false
      },

      // Has scrollable content?
      scrollable: {
        type: Boolean,
        default: true
      },

      // Modal's max width
      maxWidth: {
        type: String,
        default: 'auto'
      },

      // Modal's width
      width: {
        type: String,
        default: 'auto'
      },

      // Sets the background color
      backgroundColor: {
        type: String,
        default: 'white'
      },

      // Alert type modal
      alert: {
        type: Boolean,
        default: false
      },

      holdingPhone: {
        type: Boolean,
        default: false
      },

      // Success type modal
      success: {
        type: Boolean,
        default: false
      },

      // Logo type modal
      logo: {
        type: Boolean,
        default: false
      },

      // End Attendance type modal
      endAttendance: {
        type: Boolean,
        default: false
      },

      // Sets the header color
      headerColor: {
        type: String,
        default: 'primary'
      },

      // Sets the header icon
      headerIcon: {
        type: String,
        default: null
      },

      // Sets the header title
      headerTitle: {
        type: String,
        default: null
      },

      // Hides the header
      hideHeader: {
        type: Boolean,
        default: false
      },

      // Sets the content icon
      contentIcon: {
        type: String,
        default: null
      },

      // Sets the content title
      contentTitle: {
        type: String,
        default: null
      },

      // Hides the display of the overlay
      hideOverlay: {
        type: Boolean,
        default: false
      },

      // Sets the overlay color
      overlayColor: {
        type: String,
        default: '#00000088'
      },

      // Sets the overlay opacity
      overlayOpacity: {
        type: [Number, String],
        default: 1
      },

      // Shows the display of the cancel button
      hasCancel: {
        type: Boolean,
        default: false
      },

      // Shows the display of the confirm button
      hasConfirm: {
        type: Boolean,
        default: false
      },

      // Shows the display of the header/content close button
      hasClose: {
        type: Boolean,
        default: false
      },

      // Shows the display of the header info button
      hasHeaderInfo: {
        type: Boolean,
        default: false
      },

      // Sets the cancel button text
      cancelText: {
        type: String,
        default: ''
      },

      // Sets the confirm button text
      confirmText: {
        type: String,
        default: ''
      },

      // Invert actions buttons
      invertActions: {
        type: Boolean,
        default: false
      },
      generalError: {
        type: Boolean,
        default: false
      },

      // Function to handle cancel button and action
      handleCancel: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle close action
      handleClose: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle confirm button and action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle header info button and action
      handleHeaderInfo: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      opened: false,
      email: null,
      last_email: null,
      backendError: null,
      loading: false,
      error: null
    }),

    beforeCreate() {
      this.email = null;
      this.last_email = null;
      this.backendError = null;
      this.loading = false;
      this.error = null;
    },

    created() {
      // 
    },

    beforeMount() {
      this.email = null;
      this.last_email = null;
      this.backendError = null;
      this.loading = false;
      this.error = null;
    },

    mounted() {
      // 
    },

    methods: {
      ...mapActions('data/register', [
        'updatePatientEmail' // -> map `this.updatePatientEmail()` to `this.$store.dispatch('updatePatientEmail')`
      ]),

      ...mapActions('data/general', [
        'patientResendSms', // -> map `this.patientResendSms()` to `this.$store.dispatch('patientResendSms')`
      ]),

      ...mapMutations('data/patient', [
        'setEmail', // -> map `this.setEmail()` to `this.$store.dispatch('setEmail')`
        'setPhone', // -> map `this.setPhone()` to `this.$store.dispatch('setPhone')`
        'setEmailVerified', // -> map `this.setEmailVerified()` to `this.$store.dispatch('setEmailVerified')`
      ]),

      clearBackendError() {
        this.backendError = null;
      },
      validateEnterEmailCode(){
        this.$v.$touch();

        if (this.$v.email.$invalid) return;
        this.loading = true;
        this.updatePatientEmail({
          member_id: this.memberId,
          email: this.email
        }).then(() => {
          this.setEmail(this.email);
          this.setEmailVerified(false);
          this.$emit('handleConfirm');
          this.$emit('handleClose');
        }).catch((error) => {
          this.loading = false;
          this.last_email = this.email
          if (error.data.error.message == 'PatientService->updatePatient: Email is already in use') {
            this.backendError = this.$t('validate_phone_number.email_in_use');
          } else {
            this.backendError = this.$t('general.general_error');

            console.log(error)
          }
        }).finally(() => {
          this.loading = false;
        });

      },
      resendCodeNumberEmailCode() {
        if (!this.email) return;
        let payload = {
          member_id: this.memberId,
          type: 'email'
        };

        this.patientResendSms(payload).then(() => {

        }).catch((error) => {
          this.error = error;
          console.error(error);
          this.backendError = this.$t('general.general_error');
        }).finally(() => {
          this.loading = false;
        });
      },
    },

    computed: {
      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo', // -> this.getCompanyLogo
      }),

      ...mapGetters('data/login', {
        loginMemberId: 'getMemberId',  // -> this.memberId
      }),

      memberId() {
        return this.loginMemberId || this.registerMemberId;
      },

      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.email && errors.push(this.$t('login.email_invalid'));
        !this.$v.email.required && errors.push(this.$t('validate_phone_number.required_email'));
        if (this.backendError && this.last_email == this.email) {
          errors.push(this.backendError);
        }
        return errors;
      },
    },

    watch: {
      dialog(newDialog) {
        this.opened = newDialog;
      }
    },

    validations: {
      email: {
        required,
        email
      },
    },
  };
</script>

<style lang="scss" scoped>
  .general-modal {
    min-height: 250px;
    min-width: 400px;
  }

  .left {
    left: 0;
    position: absolute;
    transform: translate(0, -50%);
    width: inherit;
  }

  .right {
    position: absolute;
    right: 0;
    transform: translate(0, -50%);
    width: inherit;
  }

  .bottom {
    bottom: 0;
    position: absolute;
    transform: none;
    width: inherit;
  }

  .top {
    position: absolute;
    top: 0;
    transform: none;
    width: inherit;
  }

  .header {
    padding: 0;
    width: 100%;
  }

  .header-background {
    background: url('@/assets/general/modal_header_background.svg');
    background-position: bottom;
    background-repeat: repeat;
    background-size: initial;
    height: calc(100% - 12px);
    left: 6px;
    max-width: calc(100% - 12px);
    opacity: 0.3;
    position: absolute;
    top: 6px;
    width: calc(100% - 12px);
  }

  .header-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .header-image {
    height: calc(100% - 12px);
  }

  .slot-container {
    height: calc(100% - 56px);
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    //
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 56px);
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 72px);
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 72px);
    }
  }
</style>
