<template>
  <v-container
    class="phone-code-verification ma-0 pa-0"
  >
    <v-form
      autocomplete="off"
      class="py-4"
    >
      <h2
        :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
        class="outerSpace--text text-center font-weight-medium"
      >
        {{ $t('validate_phone_number.type_validation_code') }}
      </h2>

      <v-row class="img-phone-code pt-10 mx-auto">
        <v-col
          class="px-0 pt-5"
          cols="12"
          md="12"
        >
          <v-img
            :src="require('@/assets/general/phone_code_required.svg')"
            contain
          />
        </v-col>
      </v-row>

      <v-row
        class="pt-12"
      >
        <v-col
          class="px-0"
          cols="12"
          md="12"
        >
          <v-otp-input
            ref="otpInput"
            v-model="otpInput"
            :should-auto-focus="true"
            :error-messages="phoneNumberErrors"
            @complete="onCompleteHandler"
            class="cy-code-number-valid-text text-size code-number pa-0 mx-auto"
            height="70"
            length="5"
            type="number"
          />
        </v-col>
      </v-row>

      <div
        class="text-center pa-2 primary--text" v-if="success"
      >
        <b>{{ $t('validate_phone_number.success_code') }}</b>
      </div>

      <div
        class="text-center pa-2 red--text" v-if="error"
      >
        <b>{{ $t('validate_phone_number.invalid_code') }}</b>
      </div>

      <div
        class="text-center pa-2"
        v-if="!viaEmail"
      >
        {{ $t('validate_phone_number.code_sent_to') }} <br /> <b>{{formatPhoneNumber(phone_number)}}</b>
      </div>

      <div
          class="text-center pa-2"
          v-if="viaEmail"
      >
        {{ $t('validate_phone_number.code_sent_to') }} <b>{{email}}</b>
      </div>

      <p
        class="text-center text-h4 font-weight-bold secondary--text"
      >
        {{ countTime }}
      </p>

      <!-- Actions -->
      <v-col
        class="mt-12"
      >
        <v-row
          :class="{ 'text-center': $device.mobile }"
          :cols="$device.mobile ? 12 : 6"
          :md="$device.mobile ? 12 : 6"
          class="d-flex justify-center px-0 pt-0 mb-5"
        >
          <v-btn
            v-if="!viaEmail"
            :class="{
              'disabled-button-dark': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
            }"
            :loading="loading"
            :min-width="$device.mobile ? 165 : 360"
            @click="resendCodeNumberPhoneCode"
            class="text-capitalize outline-border"
            color="primary"
            dark
            depressed
            height="46"
            max-width="360"
            rounded
          >
            {{ $t('validate_phone_number.resend_code') }}
          </v-btn>

          <v-btn
              v-if="viaEmail"
              :class="{
              'disabled-button-dark': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
            }"
              :loading="loading"
              :min-width="$device.mobile ? 165 : 360"
              @click="resendCodeNumberEmailCode"
              class="text-capitalize outline-border"
              color="primary"
              dark
              depressed
              height="46"
              max-width="360"
              rounded
          >
            {{ $t('validate_phone_number.resend_code') }}
          </v-btn>
        </v-row>

        <v-row
          :class="{ 'text-center': $device.mobile }"
          :cols="$device.mobile ? 12 : 6"
          :md="$device.mobile ? 12 : 6"
          class="d-flex justify-center px-0 pt-0 mb-5"
        >
          <v-btn
            v-if="!viaEmail"
            :class="{
              'disabled-button': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
            }"
            :loading="loading"
            :min-width="$device.mobile ? 165 : 360"
            @click="handlePhoneNumberChange"
            class="text-capitalize primary--text"
            color="primary"
            depressed
            height="46"
            max-width="360"
            outlined
            rounded
          >
            {{ $t('validate_phone_number.change_number') }}
          </v-btn>

          <v-btn
              v-if="viaEmail"
              :class="{
                'disabled-button': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
              }"
              :loading="loading"
              :min-width="$device.mobile ? 165 : 360"
              @click="handleEmailChange"
              class="text-capitalize primary--text"
              color="primary"
              depressed
              height="46"
              max-width="360"
              outlined
              rounded
          >
            {{ $t('register.change_email') }}
          </v-btn>
        </v-row>
        <v-row
            v-if="typeChanger"
            :class="{ 'text-center': $device.mobile }"
            :cols="$device.mobile ? 12 : 6"
            :md="$device.mobile ? 12 : 6"
            class="d-flex justify-center px-0 pt-0"
        >
          <v-btn
              v-if="!viaEmail"
              :class="{
                'disabled-button-dark': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
              }"
              :loading="loading"
              :min-width="$device.mobile ? 165 : 360"
              @click="changeViaEmail(true)"
              class="text-capitalize outline-border"
              color="outerSpace"
              dark
              depressed
              height="46"
              max-width="360"
              rounded
          >
            {{ $t('register.change_to_email') }}
          </v-btn>
          <v-btn
              v-if="viaEmail"
              :loading="loading"
              :class="{
                'disabled-button-dark': !duration || (duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0)
              }"
              :min-width="$device.mobile ? 165 : 360"
              @click="changeViaEmail(false)"
              class="text-capitalize outline-border"
              color="outerSpace"
              dark
              depressed
              height="46"
              max-width="360"
              rounded
          >
            {{ $t('register.change_to_phone') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-form>

    <!-- Change phone number modal -->
    <GeneralModal
      :dialog="dialogPhoneChange"
      :header-close="false"
      @handleClose="closePhoneChange"
      has-close
      :header-title="!viaEmail ? 'Alterar número de celular' : 'Alterar E-mail'"
      invert-actions
      width="550"
    >
      <v-form
        autocomplete="off"
      >
        <v-row
          class="ma-0"
        >
          <v-col
            v-if="successPhoneChange"
            class="px-0 pt-0"
            cols="12"
            md="12"
          >
            <span
              class="outerSpace--text"
              v-if="!viaEmail"
            >
              {{ $t('validate_phone_number.phone_changed') }}
            </span>
            <span
                class="outerSpace--text"
                v-if="viaEmail"
            >
              {{ $t('validate_phone_number.email_changed') }}
            </span>
          </v-col>

          <v-col
            class="px-0 pt-0"
            cols="12"
            md="12"
          >
            <v-text-field
              v-if="!viaEmail"
              v-model.trim="phone_number"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :readonly="successPhoneChange"
              :backgroundColor="successPhoneChange ? 'antiFlashWhite' : 'white'"
              :error-messages="phoneNumberErrors"
              :hide-details="successPhoneChange"
              :label="successPhoneChange ? '' : $t('validate_phone_number.enter_new_mobile_number')"
              :placeholder="$t('validate_phone_number.enter_new_mobile_number')"
              @blur="$v.phone_number.$touch()"
              background-color="transparent"
              class="cy-mobile-number-valid-text mobile-number pa-0"
              persistent-placeholder
              height="50"
              outlined
            />
            <v-text-field
                v-if="viaEmail"
                v-model.trim="alter_email"
                :readonly="successPhoneChange"
                :backgroundColor="successPhoneChange ? 'antiFlashWhite' : 'white'"
                :error-messages="emailErrors"
                :hide-details="successPhoneChange"
                :label="successPhoneChange ? '' : $t('validate_phone_number.enter_new_email')"
                :placeholder="$t('validate_phone_number.enter_new_email')"
                @blur="$v.alter_email.$touch()"
                @change="clearBackendError()"
                background-color="transparent"
                class="cy-mobile-number-valid-text mobile-number pa-0"
                persistent-placeholder
                height="50"
                outlined
            />
          </v-col>
        </v-row>

        <v-row
          v-if="!successPhoneChange"
          class="ma-0"
        >
          <v-col
            class="px-0 pt-0"
            cols="6"
            md="6"
          >
            <v-btn
              :min-width="$device.mobile ? 165 : 175"
              @click="closePhoneChange"
              class="cy-cancel-button cancel text-capitalize outerSpace--text outline-border"
              depressed
              max-width="195"
              min-height="46"
              rounded
            >
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col
            class="px-0 pt-0"
            cols="6"
            md="6"
          >
            <v-btn
              v-if="!viaEmail"
              :min-width="$device.mobile ? 165 : 175"
              :loading="loading"
              @click="validateEnterNumberPhoneCode"
              class="cy-send-button confirm text-capitalize ma-0"
              color="outerSpace"
              dark
              depressed
              max-width="195"
              height="46"
              rounded
            >
              {{ $t('general.confirm') }}
            </v-btn>

            <v-btn
                v-if="viaEmail"
                :min-width="$device.mobile ? 165 : 175"
                :loading="loading"
                @click="validateEnterEmailCode"
                class="cy-send-button confirm text-capitalize ma-0"
                color="outerSpace"
                dark
                depressed
                max-width="195"
                height="46"
                rounded
            >
              {{ $t('general.confirm') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-else
        >
          <v-col
            class="px-0 pt-0 text-center"
            cols="12"
            md="12"
          >
            <v-btn
              :min-width="$device.mobile ? 165 : 175"
              @click="donePhoneChange"
              class="cy-done-phone-change-button done-phone-change confirm text-capitalize"
              color="outerSpace"
              dark
              depressed
              max-width="195"
              height="46"
              rounded
            >
              {{ $t('general.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </GeneralModal>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { minLength, required, email } from 'vuelidate/lib/validators';
  import GeneralModal from '@/components/general/GeneralModal';

  export default {
    name: 'PhoneCodeVerification',

    components: {
      GeneralModal,
    },

    props: {
      // Function to handle confirm action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
      typeChanger: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      loading: false,
      dialogPhoneChange: false,
      error: false,
      otpInput: null,
      phone_number: null,
      alter_email: null,
      success: false,
      successPhoneChange: false,
      duration: null,
      countdownInterval: null,
      password: null,
      email: null,
      countTime: '01:00',
      backendError: null,
      last_email: null
    }),

    created() {
      this.phone_number = this.registerData.phone ? this.registerData.phone : this.profileData.phone;
      this.password = this.registerData.password;
      this.email = this.registerData.email ? this.registerData.email : this.profileData.email;
      this.handleToken();
    },

    mounted() {
      if (this.viaEmail) {
        this.resendCodeNumberEmailCode(false);
      } else {
        this.resendCodeNumberPhoneCode(false);
      }

      this.startCountDown();
    },

    beforeDestroy() {
      this.resetCountTime();
    },

    methods: {
      ...mapActions('data/general', [
        'patientConfirmSms', // -> map `this.patientConfirmSms()` to `this.$store.dispatch('patientConfirmSms')`
        'patientResendSms', // -> map `this.patientResendSms()` to `this.$store.dispatch('patientResendSms')`
        'patientResendEmail',  // -> map `this.patientResendEmail()` to `this.$store.dispatch('patientResendEmail')`
        'patientConfirmEmail' // -> map `this.patientConfirmEmail()` to `this.$store.dispatch('patientConfirmEmail')`
      ]),

      ...mapActions('data/register', [
        'updatePatientEmail' // -> map `this.updatePatientEmail()` to `this.$store.dispatch('updatePatientEmail')`
      ]),


      ...mapMutations('data/register', [
        'setPhoneNumber', // this.setPhoneNumber
        'setViaEmail', // this.setViaEmail
        'setEmail' // this.setEmail
      ]),

      ...mapMutations('data/patient', {
        setPhoneNumberLogged: 'setPhoneNumber', // this.setPhoneNumberLogged
        setEmailLogged: 'setEmail' // this.setEmailLogged
      }),

      ...mapActions('data/login', [
        'patientAuthorize' // -> map `this.patientAuthorize()` to `this.$store.dispatch('patientAuthorize')`
      ]),

      onCompleteHandler(){
        if (this.otpInput.length === 5) {
          if (this.loginMemberId){
            if (this.viaEmail) {
              this.handleConfirmEmailCode();
              return;
            }
            this.handleConfirmSmsCode();
          }else {
            if (this.viaEmail) {
              this.confirmCodeEmail()
              return;
            }
            this.confirmCodeSms()
          }
        }
      },

      formatPhoneNumber(phone) {
        phone = phone.replace(/\D/g, ""); // Remove caracteres não numéricos

        if (phone.length > 10) {
          return phone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
        } else {
          return phone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        }
      },

      changeViaEmail(email) {
        this.startCountDown();
        this.setViaEmail(email);
        if (email) {
          this.resendCodeNumberEmailCode();
          return
        }

        this.resendCodeNumberPhoneCode();
      },

      handleDialogPhoneChange(){
        this.dialogPhoneChange = true;
      },

      closePhoneChange(){
        this.successPhoneChange = false;
        this.dialogPhoneChange = false;
      },

      validateEnterNumberPhoneCode(){
        this.$v.$touch();

        if (this.$v.$invalid) return;

        if (!this.profileData || !this.this.profileData.phone) {
          this.setPhoneNumber(this.phone_number);
        } else {
          this.setPhoneNumberLogged(this.phone_number);
        }

        this.resetCountTime();
        this.resendCodeNumberPhoneCode();

        this.successPhoneChange = true;
      },

      validateEnterEmailCode(){
        this.$v.$touch();

        if (this.$v.alter_email.$invalid) return;

        this.resetCountTime();
        this.updatePatientEmail({
          member_id: this.memberId,
          email: this.alter_email
        }).then(() => {
          this.resendCodeNumberEmailCode();
          this.successPhoneChange = true;
          if (!this.profileData || !this.profileData.email) {
            this.setEmail(this.alter_email);
          } else {
            this.setEmailLogged(this.alter_email);
          }

          this.email = this.alter_email;
        }).catch((error) => {
          this.last_email = this.alter_email
          if (error.data && error.data.error &&error.data.error.message == 'PatientService->updatePatient: Email is already in use') {
            this.backendError = this.$t('validate_phone_number.email_in_use');
          } else {
            this.backendError = this.$t('general.general_error');
          }
          console.error(error)
        });

      },

      donePhoneChange() {
        this.closePhoneChange();
      },

      startCountDown() {
        this.resetCountTime();

        let eventTime = this.$moment('2023-01-01 00:01:00');
        let currentTime = this.$moment('2023-01-01 00:00:00');
        let diffTime = eventTime.unix() - currentTime.unix();
        let interval = 1000;
        this.duration = this.$moment.duration(diffTime*1000, 'milliseconds');

        this.countdownInterval = setInterval(() => {
          this.duration = this.$moment.duration(this.duration - interval, 'milliseconds');

          this.countTime = `
            ${
              this.duration.minutes() < 10 ? '0'+this.duration.minutes() : this.duration.minutes()
            }:${
              this.duration.seconds() < 10 ? '0'+this.duration.seconds() : this.duration.seconds()
            }
          `;

          if (this.duration.hours() == 0 && this.duration.minutes() == 0 && this.duration.seconds() == 0) {
            clearInterval(this.countdownInterval);
          }
        }, interval);
      },

      resetCountTime() {
        clearInterval(this.countdownInterval);
        this.countTime = '01:00';
      },

      resendCodeNumberEmailCode(isResentNumber = true) {
        this.error = false;
        this.success = false;
        this.otpInput = null;

        if (!this.email) return;

        this.loading = true;

        let payload = {
          member_id: this.memberId,
          type: 'email'
        };

        this.patientResendSms(payload).then(() => {
          this.success = isResentNumber;
          this.error = false;

          this.startCountDown();
        }).catch((error) => {
          this.error = error;
          console.error(error);
        }).finally(() => {
          this.loading = false;
        });
      },

      resendCodeNumberPhoneCode(isResentNumber = true) {
        this.error = false;
        this.success = false;
        this.otpInput = null;

        if (!this.phone_number) return;

        this.loading = true;

        let payload = {
          member_id: this.memberId,
          phone: this.completeCellNumber(this.phone_number.replace(/[-() \s]/g, '')),
        };

        this.patientResendSms(payload).then((response) => {
          if(response.data.message === 'sms_sent_successfully'){
            this.success = isResentNumber;
            this.error = false;

            this.startCountDown();
          }
        }).catch((error) => {
          this.error = error;
          console.error(error);
        }).finally(() => {
          this.loading = false;
        });
      },

      confirmCodeSms() {
        this.loading = true;
        this.error = false;
        this.success = false;
        this.handleConfirmSmsCode();
      },

      confirmCodeEmail() {
        this.loading = true;
        this.error = false;
        this.success = false;
        this.handleConfirmEmailCode();
      },

      handleConfirmSmsCode() {
        let payload = {
          code: this.otpInput
        };
        this.patientConfirmSms(payload).then(() => {
          this.$emit('handleConfirm');
          localStorage.removeItem('token-fake');
        }).catch((error) => {
          this.error = error;
          console.error(error);
        }).finally(() => {
          this.loading = false;
          this.success = true;
        }); 
      },

      handleConfirmEmailCode() {
        let payload = {
          code: this.otpInput,
          type: 'email'
        };
        this.patientConfirmSms(payload).then(() => {
          this.$emit('handleConfirm');
          localStorage.removeItem('token-fake');
        }).catch((error) => {
          this.error = error;
          console.error(error);
        }).finally(() => {
          this.loading = false;
          this.success = true;
        });
      },

      handlePhoneNumberChange() {
        this.otpInput = null;

        this.handleDialogPhoneChange();
      },

      handleEmailChange() {
        this.otpInput = null;

        this.handleDialogPhoneChange();
      },

      completeCellNumber(phone) {
        if (!phone.startsWith("55")) {
          phone = "55" + phone;
        }
        return phone;
      },

      clearBackendError() {
        this.backendError = null;
      },

      handleToken() {
        let payload = {
          email: this.email,
          password: this.password
        }
        if (!payload.email || !payload.password) {
          return;
        } else {
          this.patientAuthorize(payload).then(() => {
            this.$emit('handleLogin');
          }).catch((error) => {
            this.error = error;
            console.error(error);
          }).finally(() => {
            this.loading = false;
          });
        }    
      }
    },

    computed: {
      ...mapGetters('data/register', {
        registerMemberId: 'getMemberId',  // -> this.memberId
        registerData: 'getRegisterData', // -> this.registerData
        getViaEmail: 'getViaEmail' // -> this.getViaEmail
      }),

      ...mapGetters('data/login', {
        loginMemberId: 'getMemberId',  // -> this.memberId
      }),

      ...mapGetters('data/patient', {
        profileData: 'getProfile',  // -> this.profileData
      }),

      memberId() {
        return this.loginMemberId || this.registerMemberId;
      },

      viaEmail() {
        return this.getViaEmail;
      },

      phoneNumberErrors() {
        const errors = [];
        if (!this.$v.phone_number.$dirty) return errors;
        !this.$v.phone_number.required && errors.push(this.$t('general.required'));
        !this.$v.phone_number.minLength && errors.push(this.$t('validate_phone_number.phone_number_invalid'));
        return errors;
      },

      emailErrors() {
        const errors = [];
        if (!this.$v.alter_email.$dirty) return errors;
        !this.$v.alter_email.email && errors.push(this.$t('login.email_invalid'));
        !this.$v.alter_email.required && errors.push(this.$t('validate_phone_number.required_email'));
        if (this.backendError && this.last_email == this.alter_email) {
          errors.push(this.backendError);
        }
        return errors;
      },
    },

    watch: {
      otpInput(){
        this.onCompleteHandler();
      }
    },

    validations: {
      phone_number: {
        required,
        minLength: minLength(15),
      },
      alter_email: {
        required,
        email
      },
    },
  };
</script>

<style lang="scss" scoped>
  .phone-code-verification {
    .img-phone-code {
      width: 105.99px;
      height: 149.34px;
    }

    .text-size {
      width: 300px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .phone-code-verification {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .phone-code-verification {
      //
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .phone-code-verification {
      //
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .phone-code-verification {
      //
    }
  }
</style>

