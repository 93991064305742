import { br } from './translations/br';
import VueI18n from 'vue-i18n';

const messages = {
  br,
};

export default {
  init() {
    let language = 'br';

    const i18n = new VueI18n({
      locale: language,
      messages,
      escapeParameterHtml: false
    });

    return i18n;
  }
};