export default {
  login: {
    consent_term: 'Termo de\n Consentimento',
    email: 'EMAIL',
    email_invalid: 'E-mail inválido.',
    cpf_invalid: 'CPF inválido.',
    email_placeholder: 'Digite seu endereço de email',
    forgot_password: 'Esqueci minha senha',
    login: 'Entrar',
    login_error: 'E-mail ou senha incorretos.',
    password: 'SENHA',
    password_placeholder: 'Digite sua senha',
    privacy_policy: 'Política de\n Privacidade',
    register: 'Cadastrar',
    first_access: 'Primeiro Acesso',
    enter_your_email: 'Digite seu e-mail cadastrado:',
    cpf: 'CPF:',
    enter_your_cpf: 'Digite o seu CPF para receber o link de redefinição de senha em seu e-mail cadastrado:',
    email_example: 'email@email.com.br',
    talk_with_us: 'Fale Conosco',
    member_not_found: {
      paragraph1: 'Paciente não encontrado em nossa base de dados. Entre em contato conosco em um dos canais de atendimento que teremos prazer em atendê-lo.',
    },
    not_found_login: {
      paragraph1: 'Paciente encontrado em nossa base de dados, porém não possui cadastro em nosso aplicativo.',
      paragraph2: 'Por gentileza, realize o cadastro na tela inicial, na opção',
      paragraph3: 'Primeiro Acesso.',
    },
    email_contact: 'relacionamento@teladochealth.com',
    email_send:{
      paragraph1: 'Um e-mail foi enviado contendo o link para redefinição de senha.',
      paragraph2: 'Caso não reconheça o e-mail, por gentileza entre com contato com o responsável pela sua conta.',
    }
  }
};