<template>
  <v-container
    :class="{ 'flex-column': $device.mobile }"
    :height="$device.mobile ? '76' : '97'"
    class="home-buttons align-center d-flex justify-center flex-wrap mx-0 mt-0 pa-0"
    padless
    tile
    width="100%"
  >
    <div class='ml-15' v-if="companyId == 1">
      <p style="font-size: 14px" ><span style="color: red"><b>{{$t('general.warning_inelegible_patient.attention')}}</b></span> {{ $t('general.warning_inelegible_patient.text')}} </p>
    </div>

    <v-card
      v-for="(btn, index) in  listButtons"
      :class="{
        'ma-6': !$device.mobile,
        'flex-column my-2': $device.mobile,
        'button-2': index == 2,
        'button-3': index == 3
      }"
      :color="btn.color"
      :key="index"
      :min-width="$device.mobile ? 264 : 364"
      :disabled="btn.disabled || (index == 0 && loading)"
      :loading="index == 0 && loading"
      @click="btn.action()"
      class="card-button d-flex flex-column justify-center px-6 py-10 text-center rounded-lg"
      outlined
      height="auto"
      max-width="364"
      width="auto"
    >
      <div
        v-if="index == 0 && loading"
        class="d-flex justify-center pa-1"
      >
        <v-progress-circular
          :color="btn.disabled ? 'white' : 'secondary'"
          :size="40"
          :width="5"
          indeterminate
        />
      </div>

      <v-icon
        v-else
        :color="btn.disabled || btn.color == 'primary' ? 'white' : 'secondaryFlavourDefault'"
        x-large
      >
        {{ btn.icon }}
      </v-icon>

      <p
        :class="[ btn.disabled || btn.color == 'primary' ? 'white--text' : 'outerSpace--text' ]"
        class="title text-h5 my-3"
      >
        {{ $t(`home.${btn.title}`) }}
      </p>

      <p
        :class="[ btn.disabled || btn.color == 'primary' ? 'white--text' : 'outerSpace--text' ]"
        class="subtitle text-body-2 ma-0"
      >
        {{ $t(`home.${btn.text}`) }}
      </p>
    </v-card>

    <!-- PATIENT SELECTION -->
    <GeneralModal
      :dialog="patientSelectionDialog"
      :header-title="$t('general.patient_selection_title')"
      @handleClose="closePatientSelectionDialog"
      @handleHeaderInfo="openWarningDialog"
      has-close
      has-header-info
      width="549"
    >
      <PatientSelection
        @handleCancel="closePatientSelectionDialog"
        @handleSelect="handleSelectPatient"
      />
    </GeneralModal>

    <!-- Psychology Programs Modal -->
    <GeneralModal
      :dialog="psychologyProgramsDialog"
      :header-title="$t('general.psychology')"
      @handleClose="closePsychologyProgramsDialog"
      has-close
      width="549"
    >
      <PsychologyProgramSelection
        :dialog="psychologyProgramsDialog"
      />
    </GeneralModal>

    <!-- Warning info for patient selection -->
    <GeneralModal
      :confirm-text="$t('general.ok_got_it')"
      :dialog="warningDialog"
      @handleClose="closeWarningDialog"
      @handleConfirm="closeWarningDialog"
      alert
      has-confirm
      width="440"
    >
      <h3
        class="text-center pb-2 outerSpace--text"
      >
        {{ $t('general.warning') }}
      </h3>

      <span>
        {{ $t('general.warning_patient_selection_1') }}
      </span>

      <span
        class="font-weight-bold"
      >
        {{ $t('general.warning_patient_selection_2') }}
      </span>
    </GeneralModal>

    <!-- Warning info for sac -->
    <v-btn v-if="checkFailed" @click="handleTeste">AHHHHHHH</v-btn>
    <v-btn v-if="checkFailed" @click="handleTesteTB">BHHHHHHH</v-btn>
    <GeneralModal
        :dialog="generalError"
        :confirm-text="$t('general.ok_got_it')"
        @handleClose="handleTesteClose"
        @handleConfirm="handleTesteClose"
        error
        has-close
        has-confirm
        general-error
        width="550"
    >
      <GeneralError/>
    </GeneralModal>
    <TalkWithUsModal
        :cancel-text="$t('general.cancel')"
        :confirm-text="$t('general.send')"
        :dialog="talkWithUsDialog"
        :width="$device.mobile ? '346' : '491'"
        :height="$device.mobile ? '448' : '691'"
        @handleClose="closeTalkWithUsDialog"
        :buttons="['chatButton','whatsappButton', 'supportPhoneButton']"
        background-color="antiFlash"
        header-title="Fale Conosco"
        has-close
        has-cancel
        has-image
    >
    </TalkWithUsModal>
    <SchedulePeriodModal
        :dialog=sixPeriod
        @handleConfirm="handleTesteTBClose()"
        has-confirm
        :width="$device.mobile ? '661' : '661'"
        :height="$device.mobile ? '419' : '419'"
    ></SchedulePeriodModal>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import consts from '@/static/js/consts';
  import GeneralModal from '@/components/general/GeneralModal';
  import PatientSelection from '@/components/general/PatientSelection';
  import PsychologyProgramSelection from '@/components/general/PsychologyProgramSelection';
  import TalkWithUsModal from "@/components/general/TalkWithUsModal.vue";
  import GeneralError from "@/components/general/GeneralError.vue";
  import SchedulePeriodModal from "@/components/general/SchedulePeriodModal.vue";

  export default {
    name: 'HomeButtons',

    components: {
      GeneralError,
      TalkWithUsModal,
      GeneralModal,
      PatientSelection,
      PsychologyProgramSelection,
      SchedulePeriodModal
    },

    data: () => ({
      optProgramId: 67,
      patientSelectionDialog: false,
      psychologyProgramsDialog: false,
      listButtons: [],
      listButtonsMobile: [],
      loading: false,
      warningDialog: false,
      sacDialog: false,
      buttonDisabled: false,
      emergencyPsychologyModalSaw: false,
      talkWithUsDialog: false,
      generalError: false,
      env: ['portalweb.testadoc.com.br','portalweb.devadoc.com.br','localhost','portalweb.homolodoc.com.br'],
      checkFailed: false,
      sixPeriod: false
    }),

    created() {
      if (this.env.includes(window.location.hostname)) {
        this.checkFailed = true;
      }
      this.handlePrograms();

      this.getPatientDependents();

      this.listButtons = [
        {
          action: () => {
            if (this.patient) {
              return this.handleSelectPatient();
            }

            this.patientSelectionDialog = true;
          },
          disabled: this.companyStatusId !== 1 || this.buttonDisabled || this.companyId == 1,
          color: this.companyStatusId !== 1 || this.buttonDisabled || this.companyId == 1 ? 'spanishGray' : 'primary',
          icon: 'fa-hand-holding-medical',
          text: 'need_attendance_text',
          title: 'need_attendance_title',
        },
        {
          action: () => {
            return this.$router.push('/attendances');
          },
          disabled: false,
          color: 'cultured',
          icon: 'fa-regular fa-calendar-days',
          text: 'my_attendances_text',
          title: 'my_attendances_title',
        },
        {
          action: () => {
            return this.sac();
          },
          disabled: false,
          color: 'cultured',
          icon: 'fa-solid fa-comment-dots',
          text: 'support_text',
          title: 'support_title',
        },
        {
          action: () => {
            return this.$router.push('/profile');
          },
          disabled: false,
          color: 'cultured',
          icon: 'fa-regular fa-user',
          text: 'my_profile_text',
          title: 'my_profile_title',
        },
      ];
    },

    methods: {
      ...mapActions('data/patient', [
        'patientCompanyDependents',
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
      ]),

      ...mapMutations('data/patient', [
        'setDependents',
      ]),

      ...mapMutations('data/schedule', [
        'setPatient',
      ]),
      ownerAge() {
        return this.owner && this.owner.birthdate
            ? this.$moment().diff(this.owner.birthdate, 'years')
            : null;
      },
      handleTalkWithUs() {
        this.talkWithUsDialog = true;
      },
      closeTalkWithUsDialog() {
        this.talkWithUsDialog = false;
      },
      handleTeste() {
        this.generalError = true;
      },
      handleTesteTB() {
        this.sixPeriod = true;
      },
      handleTesteTBClose() {
        this.sixPeriod = false;
      },
      handleTesteClose() {
        this.generalError = false;
      },
      getPatientDependents(){
        this.loading = true;

        this.setDependents([]);

        this.patientCompanyDependents(this.companyId).then(() => {
          let newPatients = [];

          if (this.patients && this.patients.length) {
            //newPatients = this.patients.filter(o => !o.has_login);
            newPatients = this.patients;
          }

          let ownerAge = this.ownerAge()

          if (!newPatients.length) {
            this.setPatient({
              ...this.owner,
              age: ownerAge,
              has_login: false,
            });
          }

          this.setDependents([
            {
              ...this.owner,
              age: ownerAge,
              has_login: false,
            },
            ...this.patients
          ]);
        }).catch((error) => {
          console.error(error);
          // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
          this.setGeneralError(true);
        }).finally(() => {
          this.loading = false;
        });
      },

      closePatientSelectionDialog() {
        this.patientSelectionDialog = false;
      },

      closePsychologyProgramsDialog() {
        this.setPatient({
          ...this.owner,
          age: this.ownerAge(),
          has_login: false,
        });
        this.emergencyPsychologyModalSaw = true;
        this.psychologyProgramsDialog = false;
      },

      openWarningDialog() {
        this.warningDialog = true;
      },

      closeWarningDialog() {
        this.warningDialog = false;
      },

      handleSelectPatient() {
        const {program_info = [], program_well_being = []} = this.programInfo.data;
        this.speciality_programs = program_info;
        this.health_programs = program_well_being;

        let psychologyProgramsAccess = [...this.health_programs].filter(o => consts.PSYCHOLOGY_PROGRAMS_IDS.includes(o.program_id));
        let totalProgramsLength = (this.speciality_programs?.length || 0) + (this.health_programs?.length || 0);

        // User have only access to psychology programs and this includes OPT.
        if (
          psychologyProgramsAccess.length == totalProgramsLength
          && psychologyProgramsAccess.find(o => o.program_id == this.optProgramId)
          && !this.emergencyPsychologyModalSaw
        ) {
          // Opens modal for psychology programs list
          this.psychologyProgramsDialog = true;
          this.closePatientSelectionDialog();
        } else {
          this.$router.push('/programs');
        }

      },

      handleCloseSac() {
        this.sacDialog = false;
      },

      handlePrograms() {
        if (!this.programInfo) {
          return this.loading = false;
        }

        this.loading = true;

        const {program_info = [], program_well_being = []} = this.programInfo.data;
        this.speciality_programs = program_info;
        this.health_programs = program_well_being;

        if (
          this.speciality_programs && this.speciality_programs.length == 0
          && this.health_programs && this.health_programs.length == 0
        ) {
          this.buttonDisabled = true;
        }

        this.loading = false;
      },

      sac() {
        this.talkWithUsDialog = true;
      },
    },

    computed: {
      ...mapGetters('data/login', {
        companyId: 'getCompanyId',
        companyStatusId: 'getCompanyStatusId',
        companyStatusName: 'getCompanyStatusName',
      }),

      ...mapGetters('data/patient', {
        owner: 'getProfile',
        patients: 'getDependents',
      }),

      ...mapGetters('data/schedule', {
        patient: 'getPatient',
        programInfo: 'getPrograms',
      }),
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .home-buttons {
    margin-bottom: 85px;
    max-width: 886px;

    .card-button {
      border-color: $silverSand !important;
    }
  }
  .phone{
    -moz-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
  }

  @media screen and (max-device-width: 1773px) {
    .button-2 {
      order: 3;
    }

    .button-3 {
      order: 2;
    }
  }

  @media screen and (min-device-width: 1001px)
  and (max-device-width: 1024px){
    .button-2 {
      order: 2;
    }

    .button-3 {
      order: 3;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .home-buttons {
      margin-bottom: 82px;
      width: 100%;

      .card-button {
        flex-direction: column !important;
        height: 227px !important;
        margin: 14px !important;
        max-height: 227px !important;
        max-width: 326px !important;
        min-width: 326px !important;
        padding: 15px !important;

        .title {
          font-size: 20px;
        }

        .subtitle {
          font-size: 15px;
        }
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .home-buttons {
      margin-bottom: 36px;

      .card-button {
        &:nth-child(odd) {
          margin-right: 0 !important;
        }

        &:nth-child(even) {
          margin-left: 0 !important;
        }
      }
    }

    .button-2 {
      order: 3;
    }

    .button-3 {
      order: 2;
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-buttons {
      margin-bottom: 42px;

      .card-button {
        &:nth-child(odd) {
          margin-right: 0 !important;
        }

        &:nth-child(even) {
          margin-left: 0 !important;
        }
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .home-buttons {
      margin-bottom: 42px;

      .card-button {
        &:nth-child(odd) {
          margin-right: 0 !important;
        }

        &:nth-child(even) {
          margin-left: 0 !important;
        }
      }
    }

    .button-2 {
      order: 3;
    }

    .button-3 {
      order: 2;
    }
  }
</style>