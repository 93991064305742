<template>
  <div>
    <div
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      flat
      width="100%"
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </div>

    <div
      v-else
    >
      <div
        v-if="!select_professional"
      >
        <v-row>
          <v-col cols="12" md="6" lg="4" xl="3">
            <h5 class="professional-available">
              {{ $t('schedule.professional_available') }}
            </h5>

            <div class="d-flex" :class="$device.mobile? 'flex-column-reverse' : ' flex-column'">
              <p class="message-info-professional" :class="$device.mobile? 'mt-3' : ''">
                {{ $t('schedule.message_info_professional') }}
              </p>

              <v-text-field
                class="field-search"
                :loading="loading"
                density="compact"
                variant="solo"
                placeholder="Buscar por nome"
                append-icon="fa-magnifying-glass"
                single-line
                hide-details
                v-model="needle_search"
                @keyup="searchProfessional"
                :class="!$device.mobile? 'mb-4' : ''"
              >
                <v-icon slot="append" color="secondary">
                  fa-magnifying-glass
                </v-icon>
              </v-text-field>

              <v-btn
                  v-if="themesList.length > 0"
                  @click="show_filter_themes_modal=true"
                  small
                  plain
                  class="filter-themes"
              >
                <v-icon small class="mr-1">mdi-tune</v-icon>
                {{ $t('schedule.filters') }}
              </v-btn>
            </div>

            <v-list rounded>
              <v-list-item
                  v-for="(item, i) in professionals"
                  :key="i"
                  :value="item.id"
                  class="professional-item-list"
                  :class="(item.not_have_available_schedule ? 'v-item-inactive-dark' : 'selected-schedule-list-item mt-4')"
                  @click="loadingModal(item)"
              >
                <v-list-item-avatar>
                  <v-img :alt="`${item.name}`" :src="item.image_path"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ getProfessionalName(item) }}
                  </v-list-item-title>

                  <div v-if="item.themes.length == 0">
                    <v-list-item-subtitle
                        v-if="item.crm || !program_well_being"
                        v-text="`${item?.prof_council??''} ${item?.crm??''}`"
                    />

                    <v-list-item-subtitle
                        v-else
                        v-text="`${item?.roles[0]?.role_name ??''}`"
                    />

                    <v-list-item-subtitle
                        v-if="item.not_have_available_schedule"
                        class="unavailable"
                    >
                      {{ $t('schedule.unavailable') }}
                    </v-list-item-subtitle>
                  </div>

                  <div v-else>
                    <v-list-item-subtitle
                    >
                      {{ showProfessionalThemes(item) }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      <div>
                        <span class="touched-more-themas">{{ $t('schedule.touched_more_themas') }}</span>

                        <span class="unavailable"
                              v-if="item.not_have_available_schedule">
                          {{ $t('schedule.unavailable') }}
                        </span>
                      </div>
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>

                <v-icon size="15" color="outerSpace">
                  fa-solid fa-chevron-right
                </v-icon>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>

      <div v-if="select_professional">
        <v-row>
          <v-col cols="12" sm="4">
            <div>
              <v-list rounded :class="!$device.mobile? 'mr-5' : ''">
                <v-list-item-group v-model="selected_reset_option">

                  <!-- Professional -->
                  <v-list-item
                      v-if="select_professional"
                      value="select_new_professional"
                      class="selected-schedule-list-item mt-4"
                      :class="isMultipleAttendance ? 'no-click' : ''"
                      dense
                  >
                    <v-list-item-avatar>
                      <v-img v-if="professional?.image_path" :src="professional?.image_path"
                             :alt="`${professional?.name}`"/>
                      <v-icon v-else color="outerSpace">
                        fa-user
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ getProfessionalName(professional) }}
                      </v-list-item-title>
                      <div v-if="professional.themes.length == 0">
                        <v-list-item-subtitle
                            v-if="professional.crm || !program_well_being"
                            v-text="`${professional?.prof_council??''} ${professional?.crm??''}`"
                        />

                        <v-list-item-subtitle
                            v-else
                            v-text="`${professional?.roles[0]?.role_name ??''}`"
                        />

                        <v-list-item-subtitle
                            v-if="professional.not_have_available_schedule"
                            class="unavailable"
                        >
                          {{ $t('schedule.unavailable') }}
                        </v-list-item-subtitle>
                      </div>
                      <div v-else>
                        <v-list-item-subtitle
                        >
                          {{ showProfessionalThemes(professional) }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          <div>
                            <span class="touched-more-themas">{{ $t('schedule.touched_more_themas') }}</span>

                            <span class="unavailable"
                                  v-if="professional.not_have_available_schedule">
                            {{ $t('schedule.unavailable') }}
                          </span>
                          </div>
                        </v-list-item-subtitle>
                      </div>
                    </v-list-item-content>

                    <v-icon size="15" color="outerSpace">fa-solid fa-chevron-right</v-icon>
                  </v-list-item>
                  <!-- end Professional -->

                  <!-- Date -->
                  <v-list-item
                      v-if="select_schedule_date"
                      value="select_new_date"
                      class="selected-schedule-list-item mt-4"
                      dense
                  >
                    <v-list-item-icon class="m-0">
                      <v-icon color="secondary">fa-regular fa-calendar-days</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ $t('schedule.date') }}</v-list-item-title>

                      <v-list-item-subtitle v-text="schedule_date_format" />
                    </v-list-item-content>

                    <v-icon size="15" color="outerSpace">fa-solid fa-chevron-right</v-icon>
                  </v-list-item>
                  <!-- end Date -->

                  <!-- Hour -->
                  <v-list-item
                      v-if="select_schedule_hour"
                      value="select_new_hour"
                      class="selected-schedule-list-item mt-4"
                      dense
                  >
                    <v-list-item-icon class="d-flex justify-center align-center">
                      <v-icon color="secondary">fa-regular fa-calendar-days</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ $t('schedule.schedules') }}</v-list-item-title>

                      <v-list-item-subtitle>{{ select_schedule_hour }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-icon size="15" color="outerSpace">fa-solid fa-chevron-right</v-icon>
                  </v-list-item>
                  <!-- end Hour -->

                </v-list-item-group>
              </v-list>

              <div class="mt-10 info-multiple-calls pr-6" v-if="isMultipleAttendance && !$device.mobile">
                <p><span>{{ $t('schedule.attention_text') }}</span></p>

                <p>{{ $t('schedule.next_appointment_text') }}
                  <strong>{{ this.program.attendance_interval }} {{ $t('schedule.days_before_text') }}</strong> ou
                  <strong>{{ this.program.attendance_interval }} {{ $t('schedule.days_after_text') }}</strong>
                  {{ $t('schedule.previous_appointment_text') }}
                </p>
              </div>
            </div>
          </v-col>

          <v-divider
            v-if="!$device.mobile" vertical
          />

          <v-col
            :class="{
              'd-flex align-center': !$device.mobile && select_schedule_hour,
              'd-flex flex-column align-center': $device.mobile && select_schedule_hour,
            }"
          >
            <div v-if="!select_schedule_date" :class="!$device.mobile? 'ml-8' : ''">
              <div class="mt-2">
                <v-row>
                  <v-col :class="!$device.mobile? 'pl-0' : ''">
                    <p class="text-left pt-4 spanishGray--text">{{ $t('schedule.select_date_text') }}</p>

                    <p class="font-weight-bold">{{ $t('schedule.month_day_text') }}</p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="d-flex">
                    <div class="w-100">
                      <div
                        class="calendar-container"
                      >
                        <v-date-picker
                          v-model="select_schedule_date"
                          :allowed-dates="allowedDates"
                          :header-date-format="customFormatHeader"
                          :max="range_days.max"
                          :min="range_days.min"
                          :show-current="range_days.correct_day"
                          @update:picker-date="updatePickedMonth"
                          class="mx-auto my-0 "
                          locale="pt-br"
                          next-icon="fa-sharp fa-solid fa-circle-chevron-right"
                          no-title
                          prev-icon="fa-sharp fa-solid fa-circle-chevron-left"
                        />

                        <div
                          v-if="loadingCalendar"
                          class="calendar-loader align-center d-flex justify-center ma-0 pa-0"
                          flat
                        >
                          <v-progress-circular
                            :size="$device.mobile ? 30 : 40"
                            :width="$device.mobile ? 3 : 4"
                            color="primary"
                            indeterminate
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="select_schedule_date" :class="!$device.mobile? 'ml-8' : ''">
              <div class="mt-2" v-if="!select_schedule_hour">
                <v-row>
                  <v-col :class="!$device.mobile? 'pl-0' : ''">
                    <span class="available-hours-text">{{ $t('schedule.available_hours_text') }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="box-select-hours d-flex">
                      <v-btn
                        v-for="(item, index) in list_schedule_hours"
                        :disabled="validatingAvailableTime(item.timestamp)"
                        :key="index"
                        @click="setSelectSlotOption(item)"
                        class="hours-btn"
                        elevation="1"
                        x-small
                      >
                        {{ item.hour }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="select_schedule_hour">
              <div class="mt-2">
                <v-row class="d-flex flex-column justify-start">
                  <v-col cols="12" sm="8" md="5"
                         :class="$device.mobile? 'd-flex justify-center' : ''">
                    <v-btn elevation="0"
                           rounded
                           @click="saveScheduleOption"
                           class="next-btn">
                      {{ $t('general.next') }}
                    </v-btn>
                  </v-col>

                  <v-col v-if="!$device.mobile" cols="12" sm="8" md="5">
                    <v-btn rounded elevation="0" class="back-btn" @click="resetHour">
                      {{ $t('general.back') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="mt-10 info-multiple-calls" v-if="isMultipleAttendance && $device.mobile">
              <p><span>{{ $t('schedule.attention_text') }}</span></p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <GeneralModal
        :dialog="show_info_exist_attendance"
        persistent
        width="540"
        :has-confirm="show_info_exist_attendance"
        :confirm-text="$t('general.ok_got_it')"
        @handleConfirm="handleConfirmDialogExistAttendance"
    >
      <h2 class="text-center">
        {{ $t('general.warning') }}
      </h2>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_schedule_attendance.part_1') }}
      </p>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_schedule_attendance.part_2') }}
        <strong> {{ $t('schedule.warning_exist_schedule_attendance.part_3') }}</strong>
      </p>
    </GeneralModal>

    <GeneralModal
        :dialog="show_info_professional_detail"
        persistent
        width="800"
        :header-title="$t('schedule.select_professional')"
        :has-confirm="show_info_professional_detail"
        :has-close="show_info_professional_detail"
        :has-cancel="show_info_professional_detail"
        :confirm-text="$t('schedule.select_this_professional')"
        :cancel-text="$t('schedule.back')"
        @handleConfirm="handleConfirmProfessionalSelected"
        @handleCancel="handleCancelModal"
        @handleClose="handleCancelModal"
    >
      <h5
          class="professional-available"
      >
        {{ $t('schedule.data_of_professional') }}
      </h5>

      <v-list
          class="list-modal-professional mt-3"
      >
        <v-list-item
            @click="handleCancelModal"
        >
          <v-list-item-avatar>
            <v-img
                :alt="`${professional?.name??''}`" :src="professional?.image_path??''"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ getProfessionalName(professional) }}
            </v-list-item-title>

            <v-list-item-subtitle
                v-if="(professional && professional?.crm) || !program_well_being"
                v-text="`${professional?.prof_council??''} ${professional?.crm ??''}`"
            />

            <v-list-item-subtitle
                v-else
                v-text="`${professional?.roles ? professional?.roles[0]?.role_name : ''}`"
            />
          </v-list-item-content>

          <v-icon size="15" color="outerSpace">
            fa-solid fa-chevron-right
          </v-icon>
        </v-list-item>
      </v-list>

      <h5
        class="professional-available"
      >
        {{ $t('schedule.about_me') }}
      </h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          {{ professional?.about_me ?? '' }}
        </v-card-text>
      </fieldset>

      <h5
          class="professional-available"
      >
        {{ $t('schedule.themes') }}
      </h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                md="4"
                v-for="(themeChunk, index) in chunkedThemes"
                :key="index"
            >
              <ul>
                <li v-for="theme in themeChunk" :key="theme.id">
                  {{ theme.theme }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </fieldset>

      <h5 class="professional-available">{{ $t('schedule.training') }}</h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          {{ professional?.acronym ?? '' }}

          <v-list-item-subtitle
            v-text="`${professional?.institution??''} / ${professional?.institution_state_acronym??''}`"
          />
        </v-card-text>
      </fieldset>
    </GeneralModal>

    <ScheduleUploadFileModal
      :dialog="show_upload_file"
      @handleClose="show_upload_file=false"
    />

    <GeneralModal
      :dialog="warning_professional_unavailable"
      persistent
      width="478"
      alert
      :has-confirm="warning_professional_unavailable"
      :confirm-text="$t('general.ok_got_it')"
      @handleConfirm="handleCloseWarningDialogProfessionalUnavailable"
    >
      <h3 class="text-center">
        {{ $t('general.warning') }}
      </h3>

      <p class="text-justify">
        {{ $t('schedule.warning_unavailable') }}
      </p>
    </GeneralModal>

    <GeneralModal
      :dialog="show_modal_sac"
      :header-title="$t('faq.modal_support.title')"
      :cancel-text="$t('faq.back')"
      @handleCancel="closeSacModal"
      has-cancel
      persistent
      width="500"
    >
      <ScheduleSac/>
    </GeneralModal>

    <GeneralModal
        :dialog="show_filter_themes_modal"
        :header-title="$t('schedule.filters')"
        width="540"
        @handleCancel="show_filter_themes_modal = false"
        @handleConfirm="applyFilters"
    >
      <v-row>
        <v-col cols="12" class="pa-0 mb-2">
          <span class="mb-4">{{ $t('schedule.filter_description') }}</span>
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col cols="9" class="text-center filter-themes-description-action filter-themes-description">
          <span>{{ $t('schedule.themes') }}</span>
        </v-col>
        <v-col cols="3" class="filter-themes-description">
          <v-btn
              small
              outlined
              @click="clearFilters"
              class="text-capitalize filter-themes-btn-clear"
          >
            {{ $t('schedule.filters_clear') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-for="(theme, index) in themesList" :key="index" class="filter-themes-rows mt-2 mb-2">
        <v-col cols="9" class="text-start d-flex align-center justify-start">
          <span>{{ theme.theme_name }}</span>
        </v-col>
        <v-col cols="3" class="d-flex justify-center mt-0">
          <v-checkbox
              v-model="selected_themes_filter"
              :value="theme.theme_name"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
              color="primary"
              class="ma-2 card-filter-actions"
              rounded
              large
              @click="applyFilters"
          >
            {{ $t('schedule.filter_show_professionals') }}
          </v-btn>
        </v-col>
      </v-row>
    </GeneralModal>

    <GeneralModal
        :dialog="show_info_not_exist_professional"
        alert
        persistent
        width="540"
        :has-confirm="show_info_not_exist_professional"
        :confirm-text="$t('general.ok_got_it')"
        @handleConfirm="handleConfirmDialogNotExistProfessional"
    >
      <h2 class="text-center">
        Profissionais não disponíveis
      </h2>

      <p class="text-left pt-4">
        <strong>Atenção: </strong> No momento, não estão disponíveis profissionais que atendam um ou mais temas selecionados
      </p>
    </GeneralModal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import calendar from '@/mixins/calendar';
import consts from '@/static/js/consts';
import GeneralModal from '@/components/general/GeneralModal.vue';
import ScheduleSac from '@/components/schedule/ScheduleSac.vue';
import ScheduleUploadFileModal from '@/components/schedule/ScheduleUploadFileModal.vue';

export default {
  name: 'ScheduleFormProfessional',
  components: {
    GeneralModal,
    ScheduleSac,
    ScheduleUploadFileModal,
  },

  mixins: [calendar],

  data: () => ({
    loading: false,
    loadingCalendar: false,
    calendarInCurrentMonth: true,
    updatedTimestamp: null,
    select_schedule_date: null,
    select_schedule_hour: null,
    selected_reset_option: null,
    is_family_medicine: false,
    show_info_exist_attendance: false,
    available_slots_days: [],
    professionals: [],
    select_schedule_option: null,
    select_professional: false,
    show_info_professional_detail: false,
    professional: null,
    professionalsListFix: null,
    needle_search: '',
    show_upload_file: false,
    scheduled_appointments: [],
    schedule_role_name: 'scheduleUserCalendar',
    program_well_being: false,
    warning_professional_unavailable: false,
    lastAttendanceForProgramList: [],
    isMultipleAttendance: false,
    show_modal_sac: false,
    show_filter_themes_modal: false,
    selected_themes_filter: [], // Lista dos temas selecionados
    themesList: [],
    show_info_not_exist_professional: false
  }),

  created() {
    this.loading = true;
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations('data/general', [
      'setGeneralError',
      'setGeneralErrorRedirectRoute',
    ]),

    ...mapActions('data/themes', [
      'themesGet'
    ]),

    async init() {
      this.program = this.getProgram;

      if (consts.PROGRAM_WELL_BEING_IDS.includes(this.program.id)) {
        this.program_well_being = true;
      }

      if (this.program.id == 7) {
        this.is_family_medicine = true
      }

      await this.themesGet({
        program_id: this.program.id
      });

      this.themesList = this.getThemes.themes;

      this.checkExistAttendance();
    },

    checkExistAttendance() {
      this.checkPatientLastAttendance();

      if (this.attendances?.length > 0) {
        this.handleCurrentAttendances();
      }

      if (this.isAnotherAttendanceAllowed()) {
        this.isMultipleAttendance = true;
        this.scheduleSlotsReview(false);
      } else {
        this.scheduleSlotsSpecialistSchedules(false);
      }
    },

    checkPatientLastAttendance() {
      const patientID = this.getPatientLastAttendance().attendances?.patient_info?.id;

      this.lastAttendanceForProgramList = (this.getPatientLastAttendance().attendances?.programs_scheduled
          || []).filter(program => program.program_id === this.program.id && program.member_id === patientID);

      if (this.program.attendances_qty && this.lastAttendanceForProgramList.length >= this.program.attendances_qty) {
        this.openInfoExistAttendance();
      }
    },

    isAnotherAttendanceAllowed() {
      if (this.lastAttendanceForProgramList.length == 0) {
        return false;
      }

      if (this.lastAttendanceForProgramList.length < this.program.attendances_qty) {
        this.professional = {schedule_id: this.lastAttendanceForProgramList[0].doctor_info.user_info.user_schedule_id};
        this.professionalsListFix = this.professional;
        return true;
      }

      return false;
    },

    openInfoExistAttendance() {
      this.loading = false;
      this.show_info_exist_attendance = true;
    },

    async scheduleSlotsSpecialistSchedules(reschedule) {
      await this.verifyDoubleBooking(reschedule);

      if (this.getCheckDoubleBooking) {
        const doubleBooking = this.getCheckDoubleBooking.double_booking;

        let data = {
          rules: [
            {
              name: 'specialistSchedules',
              filters: {
                program_id: this.program.id,
                occupation_id: this.program.occupation_id,
                speciality_id: this.program.speciality_id,
                timestamp: this.updatedTimestamp || this.$moment().startOf('day').unix(),
                days_ahead: this.program.days_ahead,
                double_booking: doubleBooking,
                company_id: this.getCompanyId
              }
            }
          ]
        };

        this.scheduleSlots(data).then(() => {
          const schedules = this.getScheduleSlotsStorage.specialistSchedules

          const schedulesNotAvailable = schedules.schedules_not_available;

          this.professionals = schedules.scheduleInfo.map((item) => {
            item.enable = false;
            item.not_have_available_schedule = schedulesNotAvailable.includes(item.schedule_id)

            return item
          })

          this.professionals.sort((a, b) => a.not_have_available_schedule - b.not_have_available_schedule)
          this.professionalsListFix = this.professionals;
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        })
      }
    },

    async scheduleSlotsReview(reschedule) {
      await this.verifyDoubleBooking(reschedule);

      if (this.getCheckDoubleBooking) {
        const doubleBooking = this.getCheckDoubleBooking.double_booking;

        const data = {
          rules: [
            {
              name: 'scheduleUserCalendar',
              filters: {
                user_schedule_id: this.professional.schedule_id,
                program_id: this.program.id,
                occupation_id: this.program.occupation_id,
                speciality_id: this.program.speciality_id,
                timestamp: this.updatedTimestamp || this.$moment().startOf('day').unix(),
                days_ahead: this.program.days_ahead,
                double_booking: doubleBooking,
                company_id: this.getCompanyId
              }
            }
          ]
        };

        this.scheduleSlots(data).then(() => {
          if (this.isMultipleAttendance) {
            this.professional = this.getScheduleSlot().scheduleInfo[0]
            this.setProfessional(this.professional);
          }

          this.mergeSlotsWithSameStartTime();
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        })
      }
    },

    showSacModal() {
      this.show_modal_sac = true;
    },

    closeSacModal() {
      this.show_modal_sac = false;
    },

    handleConfirmDialogExistAttendance() {
      this.show_info_exist_attendance = false;

      const {program_info = [], program_well_being = []} = this.programsInfo.data;

      if (program_info.length + program_well_being.length === 1) {
        this.$router.push('/');
      } else {
        this.$router.push('/programs');
      }
    },

    setSelectSlotOption(value) {
      this.select_schedule_option = value;
      this.select_schedule_hour = value.hour;
    },

    saveScheduleOption() {
      this.setDatetime(this.select_schedule_option.timestamp)

      if (!this.program.is_program_well_being && this.getFiles.length < 5) {
        this.openUploadFile();
      } else {
        this.$router.push('/schedule/details');
      }
    },

    getProfessionalName(item) {
      if (!item) return '';

      let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
      if (programList.includes(this.program.id)) {
        return item?.name ?? '';
      }

      return item?.crm || !this.program_well_being
          ? `${this.$t('schedule.MD')} ${item?.name}`
          : item?.name ?? '';
    },

    showProfessionalThemes(item) {
      if (!item.themes || item.themes.length === 0) return '';

      // Filtra temas com affinity não nulo, clona o array, aplica ordenação e mapeamento
      const topThemes = [...item.themes]
          .filter(theme => theme.affinity !== null) // Ignora temas com affinity nulo
          .sort((a, b) => a.affinity - b.affinity)  // Ordena por afinidade
          .slice(0, 3)  // Limita aos 3 principais temas
          .map((theme) =>
              theme.theme.length > 74 ? `${theme.theme.substring(0, 74)}...` : theme.theme
          );

      // Combina os temas com ' | ' e retorna o resultado
      return topThemes.join(' | ');
    },

    openUploadFile() {
      this.show_upload_file = true;
    },

    loadingModal(item) {
      if (item.not_have_available_schedule) {
        this.handleConfirmWarningDialogProfessionalUnavailable();
        return;
      }

      this.professional = item;
      this.show_info_professional_detail = true;
    },

    handleCancelModal() {
      this.show_info_professional_detail = false;
    },

    async handleConfirmProfessionalSelected() {
      this.loading = true;
      this.setProfessional(this.professional);
      this.show_info_professional_detail = false;
      await this.scheduleSlotsReview(false);
    },

    searchProfessional() {
      this.professionals = this.needle_search
          ? this.professionalsListFix.filter(professional =>
              professional.name.toLowerCase().includes(this.needle_search.toLowerCase()))
          : this.professionalsListFix;
    },

    handleConfirmWarningDialogProfessionalUnavailable() {
      this.warning_professional_unavailable = true;
    },

    handleCloseWarningDialogProfessionalUnavailable() {
      this.warning_professional_unavailable = false;
    },

    openWhatsApp(phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, '');

      if (!phoneNumber.startsWith('55')) {
        phoneNumber = '55' + phoneNumber;
      }
      const url = `https://wa.me/${phoneNumber}`;
      window.open(url, '_blank'); // Abre o link em uma nova janela/aba
    },

    updatePickedMonth(value) {
      let newValueMoment = this.$moment(`${value}-01`);
      let isCurrentMonth = this.$moment().month() === newValueMoment.month();

      if (this.calendarInCurrentMonth == isCurrentMonth) {
        // Prevents double calls for EP
        return;
      }

      this.calendarInCurrentMonth = isCurrentMonth;

      this.updatedTimestamp = this.calendarInCurrentMonth
        ? this.$moment().unix()
        : newValueMoment.unix();
      this.updatedTimestamp = this.updatedTimestamp < this.firstAvailableDate ? this.firstAvailableDate : this.updatedTimestamp;

      this.loadingCalendar = true;
      this.scheduleSlotsReview(false);
    },

    handleScheduleChange(type) {
      if (type === 'by_professional' && this.select_professional){
        this.selected_reset_option = 'select_new_professional'
      }
    },
    clearFilters() {
      this.selected_themes_filter = [];
    },
    applyFilters() {
      this.professionals = this.selected_themes_filter.length > 0
          ? this.professionalsListFix.filter(professional =>
              professional.themes.some(theme => this.selected_themes_filter.includes(theme.theme))
          ) : this.professionalsListFix;

      this.show_filter_themes_modal = false;

      if (this.professionals.length === 0) {
        this.show_info_not_exist_professional = true;
        this.clearFilters()
        this.applyFilters()
      }
    },
    handleConfirmDialogNotExistProfessional() {
      this.show_info_not_exist_professional = false;
    },
  },

  computed: {
    ...mapGetters('data/patient', {
      attendances: 'getAttendances',
      firstAvailableDate: 'getFirstAvailableDate'
    }),

    ...mapGetters('data/schedule', {
      programsInfo: 'getPrograms',
      getFiles: 'getFiles',
      getScheduleType: 'getScheduleType',
    }),

    ...mapGetters('data/login', {
      getCompanyId: 'getCompanyId',
      getGroupId: 'getGroupId',
    }),

    ...mapGetters('data/themes', {
      getThemes: 'getThemes',
    }),

    chunkedThemes() {
      const themes = this.professional?.themes || [];
      const chunkSize = 4;
      const result = [];

      for (let i = 0; i < themes.length; i += chunkSize) {
        result.push(themes.slice(i, i + chunkSize));
      }

      return result;
    },
  },

  watch :{
    getScheduleType(type) {
      this.handleScheduleChange(type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.v-picker {
  border: 1px solid $silverSand !important;
}

.v-list {
  padding: 0 !important;
}

.selected-schedule-list-item {
  border: 1px solid;
  border-color: $spanishGray;
  border-radius: 25px;
}

.available-hours-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: $outerSpace;
}

.box-select-hours {
  justify-content: flex-start;
  flex-wrap: wrap !important;
  border: 1px solid;
  border-color: #D8D8D8;
  min-height: 10em;
  overflow-y: auto;
  height: 237px;
  width: 402px;
  padding: 24px 16px;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 0 ! important
  }

  @media screen and (max-device-width: 640px) {
    height: 237px;
    width: 100%;
    padding: 15px 6px;
    gap: 0.58em;
  }
}

.hours-btn {
  background: #FFFFFF !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: $outerSpace;
  width: 71px !important;
  height: 36px !important;
  padding: 0px 8px !important;

  @media screen and (max-device-width: 640px) {
    height: 237px;
    width: 319px;
    font-size: 18px;
    margin: 5px 0px 9px 0px;
  }
}

.next-btn {
  background-color: $outerSpace !important;
  color: $antiFlashWhite !important;
  width: 12em !important;
}

.back-btn {
  background-color: white !important;
  color: $outerSpace !important;
  width: 12em !important;
  border-color: $spanishGray !important;
  border: 0.6px solid;
}

.calendar-container {
  position: relative;
}

.calendar-loader {
  background-color: #FFFFFF90;
  bottom: 0;
  height: 284px;
  position: absolute;
  width: 292px;
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .v-picker {
    width: 100%;
  }

  .calendar-loader {
    width: 100%;
  }
}

.professional-available {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: $outerSpace;
}

.message-info-professional {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $outerSpace;
}

.fieldset-primary {
  margin-top: 10px;
  border-width: 1px;
  color: $outerSpace;
}

.list-modal-professional {
  border-radius: 7em !important;
  border: 1px solid $spanishGray !important;
}

.field-search {
  i {
    color: #00B7E6 !important;
  }
}

.unavailable {
  color: red !important;
  font-size: 12px;
}

.v-item-inactive-dark {
  color: $spanishGray !important;
  border: 1px solid $spanishGray !important;
}

.info-multiple-calls {
  font-family: Roboto;
}

.info-multiple-calls p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: justify;
}

.info-multiple-calls p span {
  color: $primary !important;
  font-size: 0.86em;
  font-weight: 700;
  line-height: 13px;
}

.info-multiple-calls label {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $spanishGray;
}

.phone-button .v-icon {
  color: $secondary;
}

.whatsapp-button .v-icon {
  color: green;
}

.phone-button,
.whatsapp-button {
  border: 1px solid $spanishGray;
  width: 14em;
  display: inline-flex;
}

.touched-more-themas{
  color: $spanishGray;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72pxpx;
}

.professional-item-list{
  border: 1px solid $spanishGray;
  border-radius: 25px;
  margin-top: 10px;
  height: 4.6em;
}

.filter-themes{
  margin-top: 10px;
  height: 4.6em;
  font-size: 16px;
  font-weight: 700;
  color: $outerSpace;
}

.filter-themes-description-action{
  background-color: $spanishGray;
  font-size: 14px;
  text-transform: uppercase !important;
}

.filter-themes-description{
  height: 5px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold;
}

.filter-themes-btn-clear{
  height: 2em !important;
  border-color: $spanishGray !important;
  border-radius: 0px !important;
}

.filter-themes-rows{
  height: 2em;
}

.filter-themes-cols{
  height: 5em !important;
}

</style>
