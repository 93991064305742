export default {
  validate_phone_number: {
    change_number: 'Alterar número',
    code_sent_to: 'Estamos enviando o código para',
    enter_new_mobile_number: 'Digite o novo número de celular:',
    enter_new_email: 'Digite o novo e-mail:',
    invalid_code: 'Código introduzido não é válido.',
    phone_changed: 'Número de celular alterado com sucesso.',
    email_changed: 'E-mail alterado com sucesso!',
    phone_number_invalid: 'Número de celular inválido.',
    email_invalid: 'E-mail inválido.',
    required_email: 'E-mail é obrigatório',
    resend_code: 'Reenviar código',
    success_code: 'Código reenviado com sucesso.',
    type_validation_code: 'Digite o código de validação',
    email_in_use: 'E-mail já está em uso'
  }
};