import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * POST
  * @param program_id: int
  * @param company_id: int
  */
  getConsentTerm({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.GET_TERMS, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        resolve(commit('setConsentTerm', response.data.data.term_text));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * GET
  * @param company_id: int
  */
  getPrivacyPolicy({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      let newEndpointUrl = consts.ENDPOINTS.GET_PRIVACY_POLICY;
      if (payload && Object.values(payload).length > 0) {
        newEndpointUrl = `${newEndpointUrl}?${new URLSearchParams(payload).toString()}`;
      }
      axios.get(newEndpointUrl, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        resolve(commit('setPrivacyPolicy', response.data.data.privacy_policy_text));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param code: string
  */
  patientConfirmSms({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_CONFIRM_SMS, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param code: string
  */
  patientResendSms({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_RESEND_SMS, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param company_id: string
  */
  companyGet({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.COMPANY_GET, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param flavour_id: string
  */
  flavourSettingsGet({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.FLAVOUR_SETTINGS_GET, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit('setCompanyLogo', response.data.data.splash_asset);
        resolve(response.data.data);
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param user_id: int
  */
  userGet({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.USER_GET, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param case_attendance_id: int
  * @param member_id: int
  */
  socketGetInfo({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.SOCKET_GET_INFO, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setSocketInfo', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
   * POST
   * @param case_attendance_id: int
   * @param attachment: file
   */
  attendanceSaveAttachment({commit}, payload = {}) {
    let formData = new FormData();
    formData.append('case_attendance_id', payload.case_attendance_id);
    formData.append('attachment', payload.attachment[0]);

    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.ATTENDANCE_SAVE_ATTACHMENT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * POST
   * @param id: int
   */
  attendanceDeleteAttachment({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.ATTENDANCE_DELETE_ATTACHMENT, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * POST
   * @param case_attendance_id: int
   */
  attendanceListAttachment({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.ATTENDANCE_LIST_ATTACHMENT, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        resolve(commit('setAttendanceAttachments', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param program_id: int
  */
  programGetInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PROGRAM_GET_INFO, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setHistoryAttendance', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param case_attendance_id: int
  * @param status_id: int
  */
  queueStatusChange({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.QUEUE_STATUS_CHANGE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  patientResendEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_RESEND_EMAIL, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

}