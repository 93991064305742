<template>
  <div
    class="support-email pa-0"
  >
    <span class="support-email-text">E-mail: <span class="ma-0 support-email-text-email" @click="redirectToEmail">{{email}}</span></span>
  </div>
</template>

<script>

  export default {
    name: 'SupportEmail',

    props: {
    },

    data: () => ({
      email: "relacionamento@teladochealth.com"
    }),

    created() {

    },

    methods: {
      redirectToEmail() {
        window.location.href = `mailto:${this.email}`;
      }
    },

    computed: {
    },
  };
</script>

<style lang="scss" scoped>
  .support-email {
    .support-email-text {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: black;
    }
    .support-email-text-email {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      color: black;
      cursor: pointer;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .support-email {
      .support-email-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: black;
      }
      .support-email-text-email {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: black;
        cursor: pointer;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .support-email {
      .support-email-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: black;
      }
      .support-email-text-email {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: black;
        cursor: pointer;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .support-email {
      .support-email-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: black;
      }
      .support-email-text-email {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: black;
        cursor: pointer;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .support-email {
      .support-email-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: black;
      }
      .support-email-text-email {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: black;
        cursor: pointer;
      }
    }
  }
</style>