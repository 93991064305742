<template>
  <v-dialog
      v-model="modalDialog"
      max-width="478px"
      max-height="284px"
      :persistent="true"
      :scrollable="false"
      width="478px"
      height="284px"
      class="choose-verification-code"
  >
    <v-card
        class="overflow-hidden"
    >
      <!-- Header -->
      <v-card-title
          class="header pa-0"
      >
        <v-toolbar
            color="primary"
            class="d-flex justify-center overflow-hidden"
            dense
            extense
            flat
            floating
            height="90"
            width="100%"
        >
          <template slot="img">
            <v-container class="header-background pa-0" />
          </template>

          <v-container class="pa-0">
            <p class="choose-verification-code-title-text ma-0">{{$t('register.validation')}}</p>
          </v-container>
        </v-toolbar>
      </v-card-title>

      <!-- Content -->
      <v-card-text
          :class="[
          $device.mobile ? 'px-5' : 'px-10'
        ]"
      >
        <!-- Content -->
        <div
            class="slot-container pt-6"
        >
          <p class="choose-verification-code-description-text">{{ $t('register.choose_verification_code') }}</p>
        </div>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions
          :class="[
          $device.mobile ? 'px-7 pb-7' : 'px-16 pb-9'
        ]"
          class="d-flex justify-center px-0"
      >
        <v-btn
            :max-width="$device.mobile ? 175 : 'auto'"
            :min-width="$device.mobile ? 135 : 195"
            @click="sendToStep(6, false)"
            class="outerSpace--text outline-border choose-verification-code-btn-text"
            depressed
            min-height="46"
            rounded
        >
          {{ $t('register.via_sms') }}
        </v-btn>

        <v-btn
            :max-width="$device.mobile ? 175 : 'auto'"
            :min-width="$device.mobile ? 135 : 195"
            @click="sendToStep(6, true)"
            class="outerSpace--text outline-border choose-verification-code-btn-text"
            depressed
            min-height="46"
            rounded
        >
          {{ $t('register.via_email') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'ChooseVerificationCode',

  components: {
  },

  props: {
    // Function to handle confirm action
    handleConfirm: {
      type: Function,
      default() {
        return () => console.log('Default function');
      }
    },
  },

  data: () => ({
    modalDialog: true
  }),

  created() {
  },
  methods: {

    ...mapMutations('data/register', [
      'setViaEmail', // this.setViaEmail
    ]),

    sendToStep(step, email) {
      this.setViaEmail(email);
      this.$emit('handleConfirm', {step: step});
    }

  }
};
</script>

<style lang="scss" scoped>
.header-background {
  background: url('@/assets/general/modal_header_background.svg');
  background-position: bottom;
  background-repeat: repeat;
  background-size: initial;
  height: calc(100% - 12px);
  left: 6px;
  max-width: calc(100% - 12px);
  opacity: 0.3;
  position: absolute;
  top: 6px;
  width: calc(100% - 12px);
}

.choose-verification-code-title-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
  color: #FFFFFF;
}

.choose-verification-code-description-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #444444;
}

.choose-verification-code-btn-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  color: #444444;

}
.choose-verification-code {

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Tablet View */
@media screen and (min-device-width: 641px)
and (max-device-width: 1024px) {
  .choose-verification-code {
    //
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .choose-verification-code  {
    //
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .choose-verification-code  {
    //
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .choose-verification-code {
    //
  }
}
</style>

