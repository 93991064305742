export default {
  register: {
    accept_terms_above: 'Aceito os termos acima',
    birthdate: 'Data de nascimento',
    cpf: 'CPF',
    cpf_invalid: 'CPF inválido.',
    create_login_password: 'Crie seu login e senha',
    email: 'E-mail',
    email_confirmation: 'Confirmar e-mail',
    email_confirmation_different: 'O endereço de confirmação de e-mail introduzido não é igual ao E-mail informado. Por favor verifique.',
    email_invalid: 'Endereço de e-mail introduzido não é válido. Por favor corrigir.',
    email_invalid_characters: 'O endereço de e-mail introduzido não deve conter caracteres especiais. Por favor corrigir.',
    gender: 'Sexo',
    name: 'Nome Completo',
    name_min_invalid: 'Nome inválido, minimo 3 characteres.',
    name_max_invalid: 'Nome inválido, máximo 100 characteres.',
    phone: 'Número de celular',
    phone_invalid: 'Número de celular inválido.',
    password: 'Senha',
    password_confirmation: 'Confirme sua nova senha',
    password_confirmation_invalid: 'A senha de confirmação introduzida não é igual ao Senha informado. Por favor verifique.',
    password_rule_min: 'Maior que 8 caracteres',
    password_rule_number: 'Um número',
    password_rule_uppercase: 'Uma letra maiúscula',
    password_rule_lowercase: 'Uma letra minúscula',
    password_rule_special_character: 'Um caracter especial (!, @, #, $ ou &)',
    password_rules: 'Regras para criação de senha:',
    read_and_accept_terms: 'Li e aceito os termos e condições de uso',
    register: 'Cadastro',
    search_patient: 'Localizar Paciente',
    search_patient_description: 'O acesso aos seus benefícios neste aplicativo é destinado apenas a empresas parceiras',
    search_patient_description_form: 'Para continuar, insira os dados abaixo:',
    register_success: 'Cadastro efetuado\ncom sucesso!',
    user_already_registered: 'Paciente já cadastrado em nossa base de dados. Caso precise redefinir a senha, acesse a opção ”Esqueci minha senha” na tela inicial ou entre em contato conosco em um dos canais de atendimento que teremos prazer em atendê-lo.',
    user_under_age: 'O cadastro em nosso aplicativo é permitido apenas para maiores de 16 anos. Menores, podem ser vinculados apenas como dependentes.',
    user_invalid_RF: 'Os dados informados não conferem com os da Receita Federal. Verifique a sua situação cadastral no site da Receita Federal.',
    user_not_in_base_lives: '', // Sem texto por falta de definição no refinamento e planning.
    social_name: 'Nome social',
    phone_warning: 'Os dados enviados por sua empresa ou prestador de saúde foram confirmados. Continue para inserir um número de celular válido e finalizar seu cadastro.',
    user_not_registered: 'Paciente não encontrado em nossa base de dados. Entre em contato conosco em um dos canais de atendimento que teremos prazer em atendê-lo.',
    register_phone: 'Cadastrar celular',
    next: "Próximo",
    register_phone_description: 'Insira o seu número de celular abaixo',
    validation: 'Validação',
    choose_verification_code: 'Selecione por onde deseja receber o código de validação:',
    via_sms: 'Via SMS',
    via_email: 'Via E-mail',
    change_to_email: 'Enviar código via E-mail',
    change_email: 'Alterar E-mail',
    change_to_phone: 'Enviar código via SMS'
  }
};