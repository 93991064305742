<template>
  <v-container
      class="update-phone ma-0 pa-0"
  >
    <v-form
        autocomplete="off"
        class="py-4"
    >
      <h2
          :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
          class="outerSpace--text text-center font-weight-medium"
      >
        {{ $t('register.register_phone') }}
      </h2>

      <v-row class="img-phone-code pt-10 mx-auto">
        <v-col
            class="px-0 pt-5"
            cols="12"
            md="12"
        >
          <v-img
              :src="require('@/assets/general/phone_code_required.svg')"
              contain
          />
        </v-col>
      </v-row>

      <v-row class="mt-12">
        <p class="description-phone">{{ $t('register.register_phone_description') }}</p>

      </v-row>

      <v-row
          class="pt-9"
      >
        <v-col
            class="px-0"
            cols="12"
            md="12"
        >
          <v-text-field
              v-model="phone_number"
              v-mask="['(##) #####-####']"
              :error-messages="phoneErrors"
              :label="$t('register.phone')"
              @blur="$v.phone_number.$touch()"
              class="cy-input-phone input-phone pa-0"
              height="37"
              required
          />
        </v-col>
      </v-row>

      <!-- Actions -->
      <v-row
          class="mt-12 align-center d-flex justify-center pt-9"
          :class="{ 'text-center': $device.mobile }"
      >
        <v-btn
            :class="{
              'disabled-button-dark': $v.$invalid
            }"
            :min-width="$device.mobile ? 165 : 195"
            @click="save"
            class="text-capitalize outline-border"
            color="outerSpace"
            dark
            depressed
            height="46"
            max-width="208"
            rounded
        >
          {{ $t('register.next') }}
        </v-btn>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'UpdatePhone',

  components: {
  },

  props: {
    // Function to handle confirm action
    handleConfirm: {
      type: Function,
      default() {
        return () => console.log('Default function');
      }
    },
  },

  data: () => ({
    phone_number: null,
  }),

  created() {
  },
  methods: {

    ...mapMutations('data/register', [
      'setPhoneNumber', // this.setPhoneNumber
    ]),
    ...mapActions('data/register', [
      'updatePatientPhone' // -> map `this.updatePatientPhone()` to `this.$store.dispatch('updatePatientPhone')`
    ]),

    completeCellNumber(phone) {
      if (!phone.startsWith("55")) {
        phone = "55" + phone;
      }
      return phone;
    },
    save() {
      let phone = this.completeCellNumber(this.phone_number).replace(/\D/g, '');
      let payload = {
        ddd: phone.slice(2, 4),
        ddi: phone.slice(0, 2),
        phone: phone.substring(4),
        member_id: this.memberId
      };

      this.updatePatientPhone(payload).then(() => {
        this.next();
      })
    },
    next() {
      this.$emit('handleConfirm');
    }

  },

  computed: {
    ...mapGetters('data/register', {
      registerMemberId: 'getMemberId',  // -> this.memberId
      registerData: 'getRegisterData', // -> this.registerData
    }),

    ...mapGetters('data/login', {
      loginMemberId: 'getMemberId',  // -> this.memberId
    }),

    memberId() {
      return this.loginMemberId || this.registerMemberId;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone_number.$dirty) return errors;
      !this.$v.phone_number.required && errors.push(this.$t('general.required'));
      (!this.$v.phone_number.minLength) && errors.push(this.$t('register.phone_invalid'));
      return errors;
    },
  },

  validations: {
    phone_number: {
      required,
      minLength: minLength(15),
    }
  },
};
</script>

<style lang="scss" scoped>
.update-phone {
  .img-phone-code {
    width: 105.99px;
  }

  .text-size {
    width: 300px;
  }

  .description-phone {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #444444;
    margin: 0;

  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Tablet View */
@media screen and (min-device-width: 641px)
and (max-device-width: 1024px) {
  .update-phone {
    //
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .update-phone  {
    //
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .update-phone  {
    //
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .update-phone  {
    //
  }
}
</style>

