<template>
  <v-container
      class="forgot-password ma-0 pa-0"
  >
    <v-form
        autocomplete="off"
    >
      <v-row
          v-if="!cpfSent && !errorDialog"
          class="ma-0"
      >
        <v-col
            class="px-0 pt-0"
            cols="12"
            md="12"
        >
          <v-text-field
              v-model.trim="cpf"
              :error-messages="cpfErrors"
              v-mask="'###.###.###-##'"
              background-color="transparent"
              class="cy-email-valid-text input-email pa-0"
              persistent-placeholder
              :placeholder="$t('login.cpf')"
              height="50"
          />
        </v-col>

        <v-col
            class="d-flex justify-space-between px-0 pt-0"
            cols="12"
            md="12"
        >
          <v-btn
              width="auto"
              :min-width="$device.mobile ? 135 : 165"
              @click="close()"
              class="cy-cancel-button cancel primary--text"
              depressed
              min-height="46"
              outlined
              rounded
              color="spanishGray"
          >
            {{ $t('general.cancel') }}
          </v-btn>

          <v-btn
              width="auto"
              :min-width="$device.mobile ? 135 : 165"
              :loading="loading"
              @click="validateForgotPassword"
              class="cy-send-button ma-0"
              :color="isCpfValid ? 'outerSpace' : 'spanishGray'"
              dark
              depressed
              min-height="46"
              rounded
          >
            {{ $t('general.send') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
          v-if="errorDialog"
          class="ma-0"
      >
        <v-col
            class="px-0 pt-0"
            cols="12"
            md="12"
        >
          <div v-if="error_type === 'member_not_found'">
            <p>{{ $t('login.member_not_found.paragraph1') }}</p>

            <div class="d-flex justify-center px-0 pt-0">
              <WhatsappButton
                  class="mr-3"
                  :height="calculateButtonHeight()"
                  :max-height="calculateButtonHeight()"
                  :width="calculateButtonWidth()"
                  :max-width="calculateButtonWidth()"
              ></WhatsappButton>
              <SupportPhoneButton
                  :height="calculateButtonHeight()"
                  :max-height="calculateButtonHeight()"
                  :width="calculateButtonWidth()"
                  :max-width="calculateButtonWidth()"
              ></SupportPhoneButton>
            </div>
            <div class="d-flex justify-center mt-8">
              <spam>Email: <b>{{ $t('login.email_contact') }}</b></spam>
            </div>
          </div>

          <div v-if="error_type === 'not_found_login'">
            <p>{{ $t('login.not_found_login.paragraph1') }}</p>
            <p>{{ $t('login.not_found_login.paragraph2') }} <b>{{ $t('login.not_found_login.paragraph3') }}</b></p>
          </div>

          <div v-if="error_type === 'invalid_cpf'">
            <p class="text-center">{{ errorMessage }}</p>
          </div>
        </v-col>

        <v-col
            class="d-flex justify-center px-0 pt-0"
            cols="12"
            md="12"
        >
          <v-btn
              width="auto"
              :min-width="$device.mobile ? 135 : 165"
              :loading="loading"
              @click="close()"
              class="cy-send-button ma-0 antiFlash--text"
              color="outerSpace"
              depressed
              min-height="46"
              rounded
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
          v-if="cpfSent && errorDialog == false"
          class="ma-0"
      >
        <v-col
            class="px-0 pt-0"
            cols="12"
            md="12"
        >
          <h4 class="text-center text-lg-center mb-4">REDEFINIÇÃO DE SENHA</h4>

          <div>
            <p>{{ $t('login.email_send.paragraph1') }}</p>

            <p><b>E-mail: {{ email_mask }}</b></p>

            <p>{{ $t('login.email_send.paragraph2') }}</p>
          </div>

          <div class="d-flex justify-center px-0 pt-0">
            <WhatsappButton
                class="mr-3"
                :height="calculateButtonHeight()"
                :max-height="calculateButtonHeight()"
                :width="calculateButtonWidth()"
                :max-width="calculateButtonWidth()"
            ></WhatsappButton>
            <SupportPhoneButton
                :height="calculateButtonHeight()"
                :max-height="calculateButtonHeight()"
                :width="calculateButtonWidth()"
                :max-width="calculateButtonWidth()"
            ></SupportPhoneButton>
          </div>

          <div class="mt-6">
            <p class="text-center">{{ $t('general.email') }}: <b> {{ $t('general.email_relationship') }}</b></p>
          </div>

        </v-col>

        <v-col
            class="d-flex justify-center pa-0"
            cols="12"
            md="12"
        >
          <v-btn
              width="auto"
              :min-width="$device.mobile ? 135 : 165"
              @click="close()"
              class="cy-ok-button text-capitalize ma-0"
              color="outerSpace"
              dark
              depressed
              min-height="46"
              rounded
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import WhatsappButton from "@/components/general/WhatsappButton.vue";
import SupportPhoneButton from "@/components/general/SupportPhoneButton.vue";

export default {
  name: 'ForgotPassword',
  components: {SupportPhoneButton, WhatsappButton},
  props: {
    handleCancel: {
      type: Function,
      default() {
        return () => console.log('Default function');
      }
    },
  },

  data: () => ({
    cpf: null,
    cpfSent: false,
    loading: false,
    email_mask: '',
    errorDialog: false,
    errorMessage: '',
    error_type: '',
  }),

  methods: {
    ...mapActions('data/login', [
      'patientForgotPassword'
    ]),

    validateForgotPassword() {
      this.$v.$touch();

      if (this.$v.$invalid
          || !this.isCpfValid
      ) {
        this.errorMessage = 'CPF inválido. Verifique os dados.';
        this.error_type = 'invalid_cpf';
        this.errorDialog = true;

        return
      }

      this.loading = true;
      let payload = {cpf: this.cpf};
      this.patientForgotPassword(payload).then((response) => {
        this.cpfSent = true;
        if (response.message === 'reset_password_email_sent') {
          this.email_mask = response.email_masked;
        } else if (response.message === 'member_not_found') {
          this.errorMessage = 'Paciente não encontrado. Verifique os dados.';
          this.errorDialog = true;
          this.error_type = 'member_not_found';
        } else if (response.message === 'not_found_login') {
          this.errorMessage = 'Este CPF não possui login registrado.';
          this.errorDialog = true;
          this.error_type = 'not_found_login';
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },

    calculateButtonHeight() {
      let height = 92;
      if (this.isMobileDevice()) {
        height = 90;
      }
      let result = height - 2
      return `${result}px`;

    },
    calculateButtonWidth() {
      let width = 247;
      let mod = 64;
      if (this.isMobileDevice()) {
        width = 187;
        mod = 45;
      }
      let result = width - (mod * 2);
      return `${result}px`
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|iphone|ipad|ipod/i.test(userAgent);
    },
    close() {
      this.clear();
      this.$emit('handleCancel');
    },
    clear() {
      this.$v.$reset();
      this.cpf = null;
      this.cpfSent = false;
      this.loading = false;
      this.email_mask = '';
      this.errorDialog = false;
      this.cpfSent = false;
    },
  },

  computed: {
    cpfErrors() {
      const errors = [];
      if (!this.$v.cpf.$dirty) return errors;
      if (!this.isValidCPF(this.cpf)) errors.push(this.$t('login.cpf_invalid'));
      if (!this.$v.cpf.required) errors.push(this.$t('general.required'));
      return errors;
    },
    isCpfValid() {
      return this.cpf && this.cpf.replace(/\D/g, '').length === 11;
    }
  },

  validations: {
    cpf: {
      required
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
