<template>
  <v-dialog
    v-model="modalDialog"
    :max-width="maxWidth"
    :max-heigh="maxHeight"
    persistent
    :width="width"
    :height="height"
    class="schedule-period-modal"
  >
    <v-card
      :color="backgroundColor"
      class="overflow-hidden"
    >
      <!-- Header -->
      <!-- Header -->
      <v-card-title
          class="header pa-0"
      >
        <v-toolbar
            :color="headerColor"
            class="d-flex justify-center overflow-hidden"
            dense
            extense
            flat
            floating
            height="90"
            width="100%"
        >
          <template slot="img">
            <v-container class="header-background pa-0" />
          </template>

          <template slot="content">
            <v-container class="pa-0" />
          </template>

          <!-- Image title -->
          <v-toolbar-title
              class="header-image d-flex"
          >
            <v-img
                :src="require('@/assets/icons/alert.svg')"
                class="alert-header"
                contain
                width="96"
            />
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <!-- Content -->
      <v-card-text
        :class="[
          $device.mobile ? 'px-3' : 'px-9',
          $device.mobile ? 'pb-7' : 'py-7',
        ]"
      >
        <div>
          <p class="schedule-period-modal-title"> {{ $t('schedule.modal_six_day_period.title')}}</p>
          <p class="schedule-period-modal-description-text"> {{ $t('schedule.modal_six_day_period.first_description')}}</p>
          <p class="schedule-period-modal-description-text" v-html="$t('schedule.modal_six_day_period.second_description', { attention: $t('schedule.modal_six_day_period.attention'), period: $t('schedule.modal_six_day_period.period') })"></p>
        </div>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions
        :class="[
          $device.mobile ? 'px-3 pb-7' : 'px-16 pb-9',
        ]"
        class="d-flex justify-center px-0"
      >
        <v-btn
            v-if="hasCancel"
            :max-width="$device.mobile ? 265 : '265'"
            :min-width="$device.mobile ? 265 : 265"
            width="$device.mobile ? 265 : 265"
            @click="$emit('handleCancel')"
            class="cancel cy-modal-cancel-button outerSpace--text outline-border"
            depressed
            min-height="46"
            height="46"
            max-height="46"
            rounded
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
            v-if="hasConfirm"
            :max-width="$device.mobile ? 265 : '265'"
            :min-width="$device.mobile ? 265 : 265"
            width="$device.mobile ? 265 : 265"
            @click="$emit('handleConfirm')"
            class="schedule-period-modal-confirm schedule-period-modal-confirm-text"
            min-height="46"
            height="46"
            max-height="46"
            rounded
        >
          {{ $t('general.ok_got_it') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'SchedulePeriodModal',

    props: {
      // Controls if modal is active or not
      dialog: {
        type: Boolean,
        required: true,
        default: false
      },
      // Modal's max width
      maxWidth: {
        type: String,
        default: 'auto'
      },

      // Modal's width
      width: {
        type: String,
        default: 'auto'
      },

      // Modal's max height
      maxHeight: {
        type: String,
        default: 'auto'
      },

      // Modal's height
      height: {
        type: String,
        default: 'auto'
      },

      // Sets the background color
      backgroundColor: {
        type: String,
        default: 'white'
      },

      // Sets the header color
      headerColor: {
        type: String,
        default: 'primary'
      },

      // Shows the display of the header/content close button
      hasConfirm: {
        type: Boolean,
        default: false
      },

      // Shows the display of the header/content close button
      hasCancel: {
        type: Boolean,
        default: false
      },

      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      handleCancel: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle confirm button and action
      handle: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      buttons: {
        type: Array,
        default: () => []
      },
    },

    data: () => ({
      modalDialog: false,
    }),

    beforeCreate() {
      // 
    },

    created() {
      // 
    },

    beforeMount() {
      // 
    },

    mounted() {
      // 
    },

    methods: {
      isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iphone|ipad|ipod/i.test(userAgent);
      },
      cancelAttendance() {
        //cancelar atendimento
      }
    },

    computed: {
      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo', // -> this.getCompanyLogo
      }),
    },

    watch: {
      dialog(newDialog) {
        this.modalDialog = newDialog;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .schedule-period-modal {
    min-height: 491px;
    min-width: 486px;
  }

  .header-background {
    background: url('@/assets/general/modal_header_background.svg');
    background-position: bottom;
    background-repeat: repeat;
    background-size: initial;
    height: calc(100% - 12px);
    left: 6px;
    max-width: calc(100% - 12px);
    opacity: 0.3;
    position: absolute;
    top: 6px;
    width: calc(100% - 12px);
  }

  .header-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .header-image {
    height: calc(100% - 12px);
  }

  .schedule-period-modal-title {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    color: #444444;
  }

  .schedule-period-modal-description-text {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #444444;
  }

  .contact-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .email-container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .schedule-period-modal-confirm {
    width: 194px;
    height: 46px !important;
    background-color: #444444 !important;
    border-radius: 100px;
  }

  .schedule-period-modal-confirm-text {
    color: white;
    margin: 0;
    font-size: 18px;
    font-family: "Roboto";
    line-height: 24px;
    font-weight: normal;
  }

  .schedule-period-modal-cancel {
    width: 194px;
    height: 46px !important;
    background: white !important;
    border-color: #C4C4C4;
    border-radius: 100px;
  }

  .schedule-period-modal-text {
    color: #444444;
    margin: 0;
    font-size: 18px;
    font-family: "Roboto";
    line-height: 24px;
    font-weight: normal;
  }
  .schedule-period-modal-image-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }
  .schedule-period-modal-image {
    width: 281px;
    height: 200px;
  }

  .slot-container {
    height: calc(100% - 56px);
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .schedule-period-modal-description-text {
      font-family: "Roboto";
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #444444;
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 56px);
    }

    .schedule-period-modal-description-text {
      font-family: "Roboto";
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #444444;
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }

    .slot-container {
      height: calc(100% - 72px);
    }

    .schedule-period-modal-description-text {
      font-family: "Roboto";
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #444444;
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .content-icon,
    .content-title {
      font-size: 20px;
    }
    .schedule-period-modal-text {
      font-family: "Roboto";
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #444444;
    }
    .slot-container {
      height: calc(100% - 72px);
    }
  }
</style>
