export default {
  schedule: {
    title_when_do_you_want_to_be_served: 'Quando quer ser atendido?',
    modal_six_day_period: {
      title: 'AVISO',
      first_description: 'Analisamos a disponibilidade da agenda do(a) profissional e no momento ele(a) não tem horários para atendimento.',
      attention: 'Atenção: ',
      second_description: "<strong>{attention}</strong>A disponibilidade de agenda do profissional para a segunda consulta é de <strong style='color: #00B7E6;'>{period}</strong> entre a primeira.",
      period: '6 dias de intervalo'
    },
    title_schedule_attendance: {
      part_1 : 'Agendar',
      part_2 : 'consulta'
    },
    select_below: 'Selecione abaixo:',
    immediate_consultation: 'Consulta imediata',
    schedule_appointment: 'Agendar consulta',
    psychology_emergency: 'Emergência psicológica',
    title_immediate_care: {
      part_1: 'Utilize a opção ',
      part_2: 'consulta imediata',
      part_3: 'para realizar uma consulta de vídeo chamada em instantes.',
    },
    by_professional: 'Por profissional',
    by_schedule: 'Por horário',
    available_hours_text: 'Horários disponíveis',
    schedules: 'Horário',
    date: 'Data',
    hour: 'Horário',
    warning_exist_immediate_attendance: {
      part_1: 'Não foi possível realizar o agendamento pois já existe uma consulta agendada para esse paciente nesta especialidade;',
      part_2: 'Para reagendar ou cancelar o atendimento, acesse Minhas Consultas',
      part_3: ' Minhas Consultas.',
    },
    warning_exist_schedule_attendance: {
      part_1: 'Não foi possível realizar o agendamento pois já existe uma consulta agendada para esse paciente nesta especialidade;',
      part_2: 'Para reagendar ou cancelar o atendimento, acesse',
      part_3: ' Minhas Consultas.',
    },
    message_info_professional: 'Toque no botão para conhecer mais sobre o profissional ou busque pelo nome. Serão exibidos apenas os profissionais com disponibilidade na agenda.',
    professional_available: 'PROFISSIONAIS DISPONÍVEIS',
    data_of_professional: 'DADOS DO PROFISSIONAL',
    about_me: 'SOBRE MIM',
    training: 'FORMAÇÂO',
    MD: "DR. ",
    select_this_professional: 'Selecionar este profissional',
    back: 'Voltar',
    select_professional: 'Escolher profissional',
    upload_file_modal_title: 'Fotos, exames e outros arquivos',
    upload_file_modal_body: 'Deseja incluir pedidos médicos, exames ou fotos na consulta? Você pode anexar até 5 arquivos abaixo:',
    upload_file_modal_action_1: 'Enviar Agora',
    upload_file_modal_action_2: 'Enviar mais tarde',
    select_date: "Selecione uma data:",
    month_day: "MÊS/DIA",
    immediate_care: 'Atendimento imediato',
    unavailable: 'INDISPONÍVEL',
    warning_unavailable: 'O profissional selecionado não possui agenda disponível no momento. Por gentileza, selecione outro profissional para prosseguir com seu agendamento.',
    attention_text: "Atenção:",
    next_appointment_text: "Sua próxima consulta deverá ser agendada em um intervalo mínimo de",
    days_before_text: "dias antes",
    days_after_text: "dias depois",
    previous_appointment_text: "da consulta agendada anteriormente.",
    contact_us_text: "Caso o profissional não tenha agenda disponível, entre em contato conosco através dos canais abaixo:",
    select_date_text: "Selecione uma data:",
    month_day_text: "MÊS/DIA",
    sac_label: "SAC",
    whatsapp_label: "WhatsApp",
    phone_number_sac: "0800 835 2362",
    phone_number_whatsapp: "(11) 94484 9739",
    modal_support_sac: {
      title: 'Suporte 0800',
      phone_number: '0800 835 2362',
      sub_title: 'Ligue gratuitamente e tire suas dúvidas',
      sub_title_2: 'Segunda a Sexta - 09h:00 as 17h:00'
    },
    attendance_time: 'Horário de atendimento',
    call_freely: 'Ligue gratuitamente',
    everyday_time: 'Todos os dias – 07:00h as 22:00h',
    warning_psychology: {
      part_1: 'todos os dias, das 07:00h às 22:00h;',
      part_2: 'A consulta de emergência psicológica é realizada por ligação telefônica;',
      part_3: 'Exclusivo para situações emergenciais como casos de pânico, transtornos e crises emocionais.',
    },
    touched_more_themas: 'TOQUE PARA VISUALIZAR TODOS OS TEMAS',
    themes: 'Temas',
    filters: "FILTROS",
    filters_clear: "LIMPAR",
    filter_description: 'Selecione uma ou mais opções abaixo para exibir os profissionais correspondentes',
    filter_show_professionals: 'Exibir profissionais',
  }
};
