<template>
  <v-container
    class="schedule-view d-flex flex-column pa-0"
    max-width="100%"
  >
    <!-- Loader -->
    <v-card
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <v-card
      v-else
    >
      <div :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]" class="content mb-10">
        <v-row :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]">
          <v-col cols="12">
            <h2
              v-if="program && program.id === 7"
              :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
              class="outerSpace--text font-weight-medium"
            >
              {{ $t('schedule.title_when_do_you_want_to_be_served') }}
            </h2>

            <h2
              v-else-if="program"
              :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
              class="outerSpace--text font-weight-bold"
            >
                {{program.name}} -
                <span class="font-weight-light">
                  {{ $t('schedule.title_schedule_attendance.part_1')}}
                  <span class="secondary--text">{{numberConsultation}}</span>
                  {{$t('schedule.title_schedule_attendance.part_2')}}
                </span>
            </h2>

            <div v-if="isFirstConsultation">
              <p
                  class="text-left pt-3 spanishGray--text"
              >
                {{ $t('schedule.select_below') }}
              </p>

              <div :class="[ $device.mobile ? 'justify-center' : 'justify-start' ]" class="d-flex">
                <v-btn-toggle
                    v-model="selectOptionSchedules"
                    mandatory
                    rounded
                    background-color="silverSand"
                    dense
                    borderless
                    color="spanishGray"
                    class="pa-1"
                    :class="[ $device.mobile ? 'w-100' : '']"
                >
                  <v-btn
                      v-if="isFamilyMedicine"
                      :class="[ $device.mobile ? 'w-50' : '']"
                      class="rounded-pill"
                      value="immediate"
                  >
                    {{ $t('schedule.immediate_consultation') }}
                  </v-btn>

                  <v-btn
                      :class="[ $device.mobile ? 'w-50' : '']"
                      class="rounded-pill"
                      value="by_schedule"
                  >
                    {{
                      isFamilyMedicine
                          ? $t('schedule.schedule_appointment')
                          : $t('schedule.by_schedule')
                    }}
                  </v-btn>

                  <v-btn
                      v-if="!isFamilyMedicine"
                      :class="[ $device.mobile  ? 'w-50' : '']"
                      class="rounded-pill"
                      value="by_professional"
                  >
                    {{ $t('schedule.by_professional') }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-col>

          <v-col v-show="selectOptionSchedules==='immediate'">
            <ScheduleFormImmediate />
          </v-col>

          <v-col v-show="selectOptionSchedules === 'by_schedule'">
            <ScheduleFormSelectDate />
          </v-col>

          <v-col v-show="selectOptionSchedules === 'by_professional'">
            <ScheduleFormProfessional />
          </v-col>

        </v-row>
      </div>
    </v-card>
    <SchedulePeriodModal
        :dialog=schedulePeriodModal
        @handleCancel="handleSchedulePeriodModalClose()"
        has-cancel
        :width="$device.mobile ? '661' : '661'"
        :height="$device.mobile ? '419' : '419'"
    ></SchedulePeriodModal>
    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import ScheduleFormImmediate from "@/components/schedule/ScheduleFormImmediate.vue";
import ScheduleFormSelectDate from "@/components/schedule/ScheduleFormSelectDate.vue";
import ScheduleFormProfessional from "@/components/schedule/ScheduleFormProfessional.vue";
import HomeFooter from "@/components/home/HomeFooter.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SchedulePeriodModal from "@/components/general/SchedulePeriodModal.vue";

export default {
  name: "ScheduleView",
  components: {SchedulePeriodModal, ScheduleFormSelectDate, HomeFooter, ScheduleFormImmediate, ScheduleFormProfessional},
  data: () => ({
    loading: false,
    isFamilyMedicine: false,
    selectOptionSchedules: null,
    program: null,
    isFirstConsultation: true,
    numberConsultation: '',
    schedulePeriodModal: false,
  }),

  created() {
    this.loading = true;
    this.resetSlots()
    this.checkSelectProgram();
  },

  mounted() {
    this.setBannerTitle(this.$t('general.banner_need_attendance'));
    this.setBannerIcon('fa-solid fa-hand-holding-medical');
  },

  methods: {
    ...mapActions('data/attendance', [
      'getPatientLastAttendance',
    ]),

    ...mapActions('data/patient', [
      'patientFirstAvailableDate',
    ]),

    ...mapActions('data/schedule', [
      'checkPeriodSlot',
    ]),

    ...mapMutations('data/general', [
      'setBannerTitle', // -> this.setBannerTitle
      'setBannerIcon', // -> this.setBannerIcon
      'setGeneralError', // -> this.setGeneralError
      'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
    ]),

    ...mapMutations('data/schedule', [
      'setScheduleType',
      'resetSlots'
    ]),

    async handleScheduleNextAttendance(userScheduleId, datetime) {
      if (this.program.id == 57) {
        let payload = {
          user_schedule_id: userScheduleId,
          selected_date: datetime
        };
        await this.checkPeriodSlot(payload).then((response) => {
          if (!response.next_period_available) {
            this.handleSchedulePeriodModal()
          }
        }).catch((error) => {
          console.error(error);
        });
        return;
      }
    },

    handleSchedulePeriodModalClose() {
      this.$router.push('/');
      this.schedulePeriodModal = false;
    },

    handleSchedulePeriodModal() {
      this.schedulePeriodModal = true;
    },

    checkSelectProgram() {
      if (this.getProgram != null) {
        this.program = this.getProgram;

        if (this.program.id === 7) {
          this.selectOptionSchedules = 'immediate'
          this.isFamilyMedicine = true
        } else {
          this.selectOptionSchedules = 'by_professional'
        }

        const dependents = this.getDependents
            .filter(item => !item.has_login && item.id != this.getProfile.id)

        let dataPatientLastAttendance = {
          member_id: this.getPatient.id,
          program_id: this.program.id,
          company_id: this.getCompanyId,
          group_id: this.getGroupId,
          holder_dependents: dependents,
          limit: this.program.attendances_qty * (dependents.length + 1),
          done_included: false
        }

        this.getPatientLastAttendance(dataPatientLastAttendance).then(() => {
          if (this.program.attendances_qty > 1 ) {
            const patientID = this.getPatient.id;

            let qtdConsultation = this.getPatientLastAttendanceData
                .attendances
                .programs_scheduled
                .filter(program => program.program_id === this.program.id && program.member_id === patientID)
                .length

            if (qtdConsultation > 0){
              this.isFirstConsultation = false;
              this.selectOptionSchedules = 'by_professional'

              let attendances = this.getPatientLastAttendanceData
                  .attendances
                  .programs_scheduled
                  .filter(program => program.program_id === this.program.id && program.member_id === patientID)

              this.handleScheduleNextAttendance(attendances[0].doctor_info.user_info.user_schedule_id, this.$moment(attendances[0].due_date + ' ' + attendances[0].due_time, "YYYY-MM-DD HH:mm").unix());
            }

            this.numberConsultation = ' '
                + (qtdConsultation + 1)
                +'ª '
          }

          let firstDate = this.$moment().unix();

          if (this.program.attendance_interval) {
              let dates = [];
              this.getPatientLastAttendanceData.attendances.programs_scheduled.forEach((attendance) => {
                dates.push(this.$moment(attendance.due_date + ' ' + attendance.due_time, "YYYY-MM-DD HH:mm").unix());

              });

              let date = this.$moment.unix(dates.reverse()[0]).add(this.program.attendance_interval, 'days');
              firstDate = date.startOf('day').unix();
          }

          this.patientFirstAvailableDate(firstDate);
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        });
      } else {
        this.loading = false;
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      }
    },
  },

  computed: {
    ...mapGetters('data/schedule', {
      getProgram: 'getProgram',
      getPatient: 'getPatient',
    }),

    ...mapGetters('data/attendance', {
      getPatientLastAttendanceData: 'getPatientLastAttendance',
    }),

    ...mapGetters('data/login', {
      getCompanyId: 'getCompanyId',
      getGroupId: 'getGroupId',
    }),

    ...mapGetters('data/patient', {
      getProfile: 'getProfile',
      getDependents: 'getDependents',
    }),
  },

  watch:{
    selectOptionSchedules(newValue) {
      this.setScheduleType(newValue);
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/styles/colors.scss';

.schedule-view {
  max-width: 100%;

  .content {
    font-size: 16px;
    padding: 1em !important;
    min-height: calc(100vh - 246px - 97px);
    margin-top: 2em;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    margin-top: 0;

    min-height: calc(100vh - 216px - 97px);
    flex-direction: column-reverse;
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    padding-bottom: 54px !important;
    margin-top: 0;

    min-height: calc(100vh - 129px - 76px);

    /* For iPhone 5 Portrait or Landscape View */
    @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
      min-height: calc(100vh - 129px - 76px);
    }

    /* For iPhone 6 and 6 plus Portrait or Landscape View */
    @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
      min-height: calc(100vh - 129px - 76px);
    }
  }

  .v-item--active {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $antiFlash !important;
  }

  .theme--light.v-btn.v-btn--has-bg {
    background-color: $silverSand;
    color: $outerSpace;
    font-size: 15px;
    @media screen and (max-device-width: 640px) {
      font-size: 3.5vw;
    }
    font-weight: 400;
  }

  .w-100{
    width: 100%;
  }

  .w-50{
    width: 50%;
  }
}

</style>
